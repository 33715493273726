import React from 'react';
import { Box } from 'tl-storybook';
import { ICellRendererParams } from 'ag-grid-community/dist/lib/rendering/cellRenderers/iCellRenderer';
import { classes } from './FileTitleRenderer.styles';

const FileTitleRenderer = (props: Partial<ICellRendererParams>) => {
  const { data, colDef } = props;

  return (
    <Box
      sx={{
        ...classes.textWrapper,
        ...(colDef?.rowDensity === 'comfortable' ? { height: '100px' } : { height: '52px' }),
      }}
    >
      <strong>{data.asset?.fileTitle}</strong>
      <div>{data.asset?.fileName}</div>
    </Box>
  );
};

export default FileTitleRenderer;
