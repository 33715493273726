import { createSlice } from '@reduxjs/toolkit';

enum Severity {
  error = 'error',
  success = 'success',
  info = 'info',
  warning = 'warning',
}
interface Alert {
  severity: Severity;
  message: string;
}

interface Mediator8Error {
  open: boolean;
  title: string;
  confirmLabel: string;
}

const UserFeedBackSlice = createSlice({
  name: 'userFeedBack',
  initialState: {
    loadingSpinner: false,
    alert: <Alert> null,
    mediator8Error: <Mediator8Error>{
      open: false,
      title: '',
      confirmLabel: '',
    },
  },
  reducers: {
    setLoadingSpinner(state, action) {
      state.loadingSpinner = action.payload;
    },
    setAlert(state, action) {
      state.alert = action.payload;
    },
    setMediator8Error(state, action) {
      state.mediator8Error = { ...state.mediator8Error, ...action.payload };
    },
  },
});

const { actions, reducer } = UserFeedBackSlice;

export const {
  setLoadingSpinner,
  setAlert,
  setMediator8Error,
} = actions;

export default reducer;
