import React from 'react';
import { Box } from 'tl-storybook';
import { ICellRendererParams } from 'ag-grid-community/dist/lib/rendering/cellRenderers/iCellRenderer';
import MediaThumbnail, { MediaSize } from '../../MediaThumbnail';
import { classes } from './PreviewRenderer.styles';

export default function PreviewRenderer(props: Partial<ICellRendererParams>) {
  const { data, colDef } = props;

  return (
    <Box
      sx={{
        ...classes.root,
        ...(colDef?.rowDensity === 'comfortable' ? { height: '100px' } : { height: '52px' }),
      }}
      data-testid="preview-renderer"
    >
      <MediaThumbnail asset={data} size={MediaSize.ICON} />
    </Box>
  );
}
