import React from 'react';
import { Box, NbcuTextField } from 'tl-storybook';
import { getFormFieldByKey } from '../assets/consts/PropertiesForms';
import { DrawerItem } from '../interfaces/drawer-item';
import { FormField } from '../interfaces/formfield';
import { createDataTestId } from './helpers';
import { formatFieldNames, getNestedData } from './utils';

function makePropertiesDrawerFormField(fieldSet: FormField, item: any, disabled: boolean, section?: string) {
  const { label } = fieldSet;
  const keys = fieldSet.key;
  let value = getNestedData(keys, item);
  if (!value || value === 'UNDEFINED') {
    value = (value === false) ? 'false' : '';
  }

  if (fieldSet.formatter) {
    value = fieldSet.formatter(value);
  }

  return (
    <Box className="properties-drawer-form-field" key={label}>
      <NbcuTextField
        data-testid={createDataTestId([section, label])}
        label={formatFieldNames(label)}
        value={formatFieldNames(label) === 'Created Date' ? value.split(' ')[2] : value || ' '}
        disabled={disabled}
        multiline
        sx={{
          backgroundColor: 'transparent',
          '& .MuiInputBase-input.MuiInput-input.Mui-disabled': {
            color: 'rgba(0, 0, 0, .87)',
            WebkitTextFillColor: 'rgba(0, 0, 0, .87)',
          },
        }}
      />
    </Box>
  );
}

export function makePropertiesDrawerFields(fieldset: FormField[], item: any, disabled: boolean, section?: string) {
  return fieldset.map((field) => makePropertiesDrawerFormField(field, item, disabled, section));
}

export function makePropertiesDrawerFieldsFromAssetColumn(
  fieldset: DrawerItem[],
  item: any,
  disabled: boolean,
  section?: string,
) {
  return fieldset.map((drawerItem) => {
    const formField = getFormFieldByKey(drawerItem.name) || {
      label: drawerItem.displayName,
      key: drawerItem.name.split('.'),
    };

    return makePropertiesDrawerFormField(formField, item, disabled, section);
  });
}
