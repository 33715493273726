import React, { useEffect } from 'react';
import {
  mui, icons, Box, NbcuTooltip, NbcuMenuItem,
} from 'tl-storybook';
import { TableButtonProps, TableMenuProps } from '../../../../interfaces/table-button';
import { ASSETS_TABLE_BUTTONS } from '../../../consts/assets-table-buttons';
import { COLLECTION_ASSETS_BUTTONS } from '../../../consts/collection-assets-buttons';
import { COLLECTION_DETAILS_BUTTONS } from '../../../consts/collection-details-buttons';
import { COLLECTIONS_TABLE_BUTTONS, COLLECTIONS_TABLE_MENU_ACTIONS } from '../../../consts/collections-table-buttons';
import { useAppSelector } from '../../../../interfaces/hooks';
import { TableType } from '../../../consts/table-type';
import { WORK_ORDERS_BUTTONS } from '../../../consts/work-orders-buttons';
import { STUDIO_SUPPLY_ORDERS_BUTTONS } from '../../../consts/studio-supply-orders-buttons';
import TableButton from './TableButton';

interface TableButtonsProps {
  buttonActions?: any;
  type: TableType;
  selectedRows?: any[];
}

const BUTTON_TYPES: { [type in TableType]?: TableButtonProps[] } = {
  assets: ASSETS_TABLE_BUTTONS,
  collectionAssets: COLLECTION_ASSETS_BUTTONS,
  collections: COLLECTIONS_TABLE_BUTTONS,
  collectionDetails: COLLECTION_DETAILS_BUTTONS,
  workOrders: WORK_ORDERS_BUTTONS,
  studioSupplyOrders: STUDIO_SUPPLY_ORDERS_BUTTONS,
};
const MENU_TYPES: { [type in TableType]?: TableMenuProps[] } = {
  assets: [],
  collections: COLLECTIONS_TABLE_MENU_ACTIONS,
};

export default function TableButtons(props: TableButtonsProps) {
  const { buttonActions, type, selectedRows } = props;
  const user = useAppSelector((state: any) => state.appUser.user);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [buttons, setButtons] = React.useState([]);

  const buttonType = BUTTON_TYPES[type] || [];
  const menuType = MENU_TYPES[type] || [];

  useEffect(() => {
    const newButtons = buttonType.map((btn) => ({
      ...btn,
      tooltipTitle: btn.getTooltipTitle(user, selectedRows),
      disabled: btn.isDisabledFunction(user, selectedRows),
    }));
    setButtons(newButtons);
  }, [selectedRows, user, setButtons]);

  const openMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const closeMenu = (action?: any) => {
    const closeFn = buttonActions[action?.text];
    if (closeFn instanceof Function) {
      closeFn();
    }
    setAnchorEl(null);
  };

  return (
    <>
      {buttons.map((btn: TableButtonProps) => (
        <Box sx={{ marginRight: '16px' }}>
          <NbcuTooltip
            title={btn.tooltipTitle}
            placement="top"
            key={btn.text}
          >
            <TableButton
              text={btn.text}
              icon={btn.icon}
              disabled={btn.disabled}
              onClick={buttonActions[btn.text]}
            />
          </NbcuTooltip>
        </Box>
      ))}
      {menuType.length > 0 && (
        <>
          <mui.IconButton
            disabled={selectedRows.length === 0}
            data-testid="table-buttons-menu"
            className="button-menu"
            onClick={openMenu}
          >
            <icons.MoreVert />
          </mui.IconButton>
          <mui.Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={closeMenu}
            transformOrigin={{ horizontal: 'left', vertical: -50 }}
          >
            {menuType.map((action: any) => (
              <NbcuTooltip
                title={action.getTooltipTitle(user, selectedRows)}
                placement="right-end"
                leaveDelay={300}
                disableFocusListener
              >
                <NbcuMenuItem
                  key={action.text}
                  onClick={() => closeMenu(action)}
                  disabled={!!action.isDisabledFunction(user, selectedRows)}
                >
                  {action.label}
                </NbcuMenuItem>
              </NbcuTooltip>
            ))}
          </mui.Menu>
        </>
      )}
    </>
  );
}
