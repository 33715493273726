import axios from 'axios';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  AgGridColumn,
  Box,
  Grid,
  icons,
  NbcuButton,
  NbcuDialog,
  NbcuMenuItem,
  NbcuMediaCard,
  NbcuModal,
  NbcuSnackbar,
  NbcuTable,
  NbcuTooltip,
  mui,
} from 'tl-storybook';
import moment from 'moment';
import cloneDeep from 'lodash.clonedeep';
import { PmamAsset } from '../../../interfaces/pmam-asset';
import { Asset } from '../../../interfaces/asset';
import { ConfigureItem } from '../../../interfaces/configure-drawer-item';
import { TableType } from '../../consts/table-type';
import TableViews from '../../consts/table-views';
import { TABLE_WRAPPER_PROPERTIES } from '../../consts/table-wrapper-properties';
import { extensionHasImage, formatFieldNames } from '../../utils';
import MediaThumbnail, { MediaSize } from '../MediaThumbnail';
import PreviewRenderer from './PreviewRenderer';
import MerlinDeliverStatusRenderer from './MerlinStatusRenderer';
import ApprovalStatusRenderer from './ApprovalStatusRenderer';
import MediatorStatusRenderer from './MediatorStatusRenderer';
import WorkOrderFileTitleRenderer from './WorkOrderFileTitleRenderer';
import FileTitleRenderer from './FileTitleRenderer';
import OrderStatusRenderer from './OrderStatusRenderer';
import BooleanRenderer from './BooleanRenderer';
import TextRenderer from './TextRenderer';
import MasterTypeRenderer from './MasterTypeRenderer';
import TableButtons from './TableButtons';
import TableOptions from './TableOptions';
import { useAppSelector, useAppDispatch } from '../../../interfaces/hooks';
import {
  setPropertiesDrawerType,
  updateSelectedAsset,
  setSelectedWorkOrder,
} from '../../slices/PropertiesDrawerSlice';
import { API_ENDPOINTS } from '../../consts/API_ENDPOINTS';
import { updatePreviewOpen, updateSelectedPreview, setWatermarkedUrl } from '../../slices/PreviewSlice';
import { setAlert } from '../../slices/ErrorSlice';
import { updateItems } from '../../slices/SelectedSlice';
import { setVideoMetaData } from '../../slices/ProxySlice';

import { classes } from './TableWrapper.styles';

interface IconButtonPropsWithTestId extends Partial<mui.IconButtonProps> {
  'data-testid': string;
}

interface ColumnProps {
  cellRenderer: React.ReactNode;
  checkboxSelection: boolean;
  headerCheckboxSelection: boolean;
  rowDensity: 'normal' | 'dense' | 'comfortable';
  width?: number;
  resizable?: boolean;
  sortable?: boolean;
  autoSize?: boolean;
}

/**
 * Watermarking proxy request
 * @see {@link https://github.com/NBCUniversalOTS/tl-shared-wmtoken-service/blob/develop/project/src/main/resources/schema/tl-shared-wmtoken-service.graphql#L65} for more details
 */
interface WmProxyOrderRequest {
  dueDate: string
  materialId: string,
  targetSystem: WmSourceSystem,
  productionNumber: string,
}

/** Asset Source systems */
enum SourceSystem {
  PMAM = 'PMAM',
  SUPPLY = 'SUPPLY',
}

/** Watermarking source system */
enum WmSourceSystem {
  MEDIATOR_8 = 'MEDIATOR_8',
  MEDIATOR_X = 'MEDIATOR_X',
  PMAM_MEDIATOR = 'PMAM_MEDIATOR',
  INTERNATIONAL = 'INTERNATIONAL'
}

export default function TableWrapper(props: Readonly<{
  buttonActions: { [key: string]: () => void };
  innerContent?: any;
  tableData: Asset[];
  tableCount: number;
  updateTablePageNumber: (page: number) => void;
  updateTablePageLimit: (limit: number) => void;
  updateSelectedData: (data: any) => void;
  updateTableSortingOrder?: (colSorting: any) => void;
  updateTableData?: (payLoad: any) => void;
  tablePage: number;
  tableLimit: number;
  type: TableType;
  onCellClicked?: (data: any) => void;
}>) {
  const {
    buttonActions,
    innerContent,
    tableData,
    tableCount,
    tablePage,
    tableLimit,
    type,
    updateTablePageNumber,
    updateTablePageLimit,
    updateSelectedData,
    updateTableSortingOrder,
    updateTableData,
    onCellClicked,
  } = props;

  const restrictedAssetDialogMessageBase = 'This asset is restricted and the proxy cannot be viewed';
  const watermarkingDialogMessageBase = 'This asset requires watermarking before it can be viewed.';
  const watermarkingDialogMessageConfirmation = 'Would you like to watermark this asset?';
  const merlinTooltipMessage = 'Missing requirements for Merlin delivery: ';

  /** Auto hide duration of snackbar (in ms) */
  const autoHideDuration = 60 * 1000;
  const consoleColor = 'background: #191919; color: #66CEDC';

  const { selectedColumns } = useAppSelector((state) => state.configureDrawer);
  const { featureFlags } = useAppSelector<any>((state) => state.featureFlags);
  const { user } = useAppSelector<any>((state) => state.appUser);
  const propertiesDrawerOpen = useAppSelector((state) => state.propertiesDrawer.propertiesDrawerOpen);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [tableView, setTableView] = useState<string>(TableViews.NORMAL);
  const [tableColumns, setTableColumns] = useState<JSX.Element[]>([]);
  const [selectedRows, setSelectedRows] = useState<any[]>([]);
  const [merlinIsDisabled, setMerlinIsDisabled] = useState<boolean>(false);
  const [showRejectModal, setShowRejectModal] = useState<boolean>(false);
  const [showRestrictModal, setShowRestrictModal] = useState<boolean>(false);
  const [rejectReason, setRejectReason] = useState<string>('');
  const [restrictReason, setRestrictReason] = useState<string>('');
  const [rejectReasonInvalid, setRejectReasonInvalid] = useState<boolean>(false);
  const [restrictedState, setRestrictedState] = useState<string | null >(null);
  const [restrictedMessage, setRestrictedMessage] = useState<string>('');
  /** Snackbar message */
  const [snackbarMessage, setSnackbarMessage] = useState<string>('');
  /** Watermarking confirmation dialog open state */
  const [watermarkConfirmationOpen, setWatermarkConfirmationOpen] = useState<boolean>(false);
  /** Watermarking confirmation dialog open state */
  const [restrictedAssetConfirmationOpen, setRestrictedAssetConfirmationOpen] = useState<boolean>(false);
  /** Preview asset holder */
  const [previewAsset, setPreviewAsset] = useState<Asset | null>(null);
  /** Asset Menu anchor */
  const [assetMenuAnchor, setAssetMenuAnchor] = useState<null | HTMLElement>();
  /** Watermarking dialog message */
  const [watermarkingDialogMessage, setWatermarkingDialogMessage] = useState<string>('');
  /** Restricted asset dialog message */
  const [restrictedAssetDialogMessage, setRestrictedAssetDialogMessage] = useState<string>('');
  const [merlinTooltipRequirements, setMerlinTooltipRequirements] = useState(new Map<string, string[]>(null));
  const [approveAssetErrorList, setApproveAssetErrorList] = useState<Record<string, string[]>>({});
  const [tooltipSnackbarMessage, setTooltipSnackbarMessage] = useState<string>('');
  const [cantApproveRejectTooltip, setCantApproveRejectTooltip] = useState<JSX.Element>(null);
  const [subClipError, setSubClipError] = useState<string>('');
  const [rowDensity, setRowDensity] = useState<'normal' | 'dense' | 'comfortable'>('normal');
  const [deleteIsDisabled, setDeleteIsDisabled] = useState<boolean>(true);
  const [deleteDisabledMessage, setDeleteDisabledMessage] = useState<string>('');
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);

  const characterLimit = 1970;

  const {
    showTableOptions,
    showTableButtons,
    hideCard,
    hideList,
    hideViewList,
    hideSettings,
  } = TABLE_WRAPPER_PROPERTIES[type];

  const mapKeyToApiField = (field: string): string => {
    const keyToApiFieldMapper: any = {
      title: 'asset_fileTitle',
      progress: 'state',
      brand: 'asset.userNetwork',
      fileName: 'asset_fileName',
      aspectRatio: 'asset.aspectRatio',
      versionType: 'asset.versionType',
      materialType: 'asset.materialType',
      mediaType: 'asset.mediaType',
      maskType: 'asset.maskType',
    };
    return keyToApiFieldMapper[field] || field;
  };

  const checkUserPolicies = () => {
    const brandDeletePolicies = user?.policies?.asset?.deleteRestriction.map((b: any) => b.description);
    const supplyAssetSelected = selectedRows.some((row) => row.sourceSystem === 'SUPPLY');
    const markedForDeletion = selectedRows.some((row) => row.pmam?.markedForDelete);
    const noAccess = selectedRows.some((row) => !brandDeletePolicies.includes(row?.brand));
    const isInvalid = noAccess || supplyAssetSelected || markedForDeletion;

    if (markedForDeletion) {
      setDeleteDisabledMessage("You can't perform this action on assets that are marked for deletion");
    } else if (supplyAssetSelected) {
      setDeleteDisabledMessage("You can't delete a supply asset.");
    } else if (noAccess) {
      setDeleteDisabledMessage("You don't have access to delete this asset(s).");
    } else {
      setDeleteDisabledMessage('');
    }
    setDeleteIsDisabled(isInvalid);
  };

  useEffect(() => {
    const newTableColumns = selectedColumns.map((column: ConfigureItem, index: number) => {
      const columnProps: ColumnProps = {
        cellRenderer: null,
        checkboxSelection: false,
        headerCheckboxSelection: false,
        rowDensity,
        width: 230,
        autoSize: column.autoSize,
        resizable: true,
        sortable: column.sortable === false ? false : true,
      };

      if (column.key === 'imageURL') {
        columnProps.cellRenderer = PreviewRenderer;
      } else if (column.key === 'mediatorProgressPercentage') {
        columnProps.cellRenderer = MediatorStatusRenderer;
      } else if (column.key === 'pmam.merlinDeliveryStatus') {
        columnProps.cellRenderer = MerlinDeliverStatusRenderer;
      } else if (column.key === 'pmam.svApprovalStatus') {
        columnProps.cellRenderer = ApprovalStatusRenderer;
      } else if (column.key === 'pmam.fileTitle') {
        columnProps.cellRenderer = FileTitleRenderer;
      } else if (column.key === 'pmam.masterType') {
        columnProps.cellRenderer = MasterTypeRenderer;
      } else if (window.location.href.includes('work-orders') && column.key === 'title') {
        columnProps.cellRenderer = WorkOrderFileTitleRenderer;
      } else if (column.key === 'frOrderStatus') {
        // NOTE: this cellRender is for the Studio Supply order dashboard
        columnProps.cellRenderer = OrderStatusRenderer;
      } else if (
        column.key === 'pmam.markedForDelete'
        || column.key === 'pmam.deactivated'
        || column.key === 'pmam.markedForDigitization'
        || column.key === 'pmam.physical'
        || column.key === 'proxyAvailable'
      ) {
        columnProps.cellRenderer = BooleanRenderer;
      } else {
        columnProps.cellRenderer = TextRenderer;
      }

      // add checkbox to the first column
      if (index === 0) {
        columnProps.checkboxSelection = true;
        columnProps.headerCheckboxSelection = true;
        columnProps.width += 72;
      }

      return (
        <AgGridColumn
          key={column.key}
          field={column.key}
          headerName={formatFieldNames(column.displayName)}
          {...columnProps}
        />
      );
    });
    setTableColumns(newTableColumns);
  }, [selectedColumns, tableData, rowDensity]);

  const toggleProperties = (open?: boolean) => {
    if (open !== undefined) {
      dispatch(setPropertiesDrawerType(open));
    } else {
      dispatch(setPropertiesDrawerType(!propertiesDrawerOpen));
    }
  };

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => {
    updateTablePageNumber(page + 1);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const limit = parseInt(event.target.value, 10);
    updateTablePageLimit(limit);
  };

  /** Get PMAM asset proxy data */
  const getPmamAssetProxyData = async (materialId: string) => axios.get(`${API_ENDPOINTS.PMAM_VIDEO_DATA}/mpd-manifest?materialId=${materialId}`)
    .catch((resp) => {
      setSnackbarMessage(resp);
    });

  /** Get asset proxy */
  const getAssetProxy = async (gtmId: string, storageSystem: string, materialId: string) => {
    /** Asset proxy request */
    const url = `${API_ENDPOINTS.ASSET_PROXY}?gtmId=${gtmId}&storageSystem=${storageSystem}&materialId=${materialId}`;

    return axios.get(url)
      .catch((err) => {
        setSnackbarMessage(err);
      });
  };

  /** Watermarking confirmation closed callback */
  const onWatermarkingConfirmationClose = async (proceed: boolean) => {
    if (proceed) {
      const { materialId, storageSystem, productionNumber } = previewAsset;

      /** Due date */
      const dueDate = moment().add(moment.duration(1, 'hours')).format();

      /** Watermarking work order request */
      const body: WmProxyOrderRequest = {
        materialId,
        targetSystem: (WmSourceSystem as any)[storageSystem],
        dueDate,
        productionNumber,
      };

      axios.post(API_ENDPOINTS.WATERMARKING_ORDER, body).then((resp) => {
        if (resp.data.error) {
          dispatch(setAlert({ severity: 'error', message: `Error: Watermarking order was not created > ${resp.data.error}` }));
          return;
        }
        dispatch(setAlert({ severity: 'success', message: `Success: Watermarking work order created for ${resp.data.createWMProxyOrder.materialId}.` }));

        setPreviewAsset(null);
      }).catch((err) => {
        dispatch(setAlert({ severity: 'error', message: `${err}` }));
      });
    }

    setWatermarkingDialogMessage('');
    setWatermarkConfirmationOpen(false);
  };

  const onRestrictedAssetConfirmationClose = () => {
    setRestrictedAssetConfirmationOpen(false);
  };

  async function canViewPmamAsset(selectedAsset: any) {
    const materialId = selectedAsset.materialId;
    const assetProxyMeta: any = await getPmamAssetProxyData(materialId);
    console.log('* >>>', assetProxyMeta);

    const {
      isRestricted,
    } = assetProxyMeta.data;

    console.log('>> assetProxy', assetProxyMeta?.data);

    if (isRestricted) {
      /** Restricted Asset */
      console.log(`%c > 2.0: SUPPLY Asset: is restricted: ${materialId}`, consoleColor);

      setRestrictedAssetDialogMessage(restrictedAssetDialogMessageBase);
      setRestrictedAssetConfirmationOpen(true);
      return false;
    }
    /** Not prerelease content or restricted */
    console.log(`%c > 1.1: PMAM Asset: Not prerelease content or restricted: ${selectedAsset.materialId}`, consoleColor);
    return true;
  }

  const promptFortWaterMarking = () => {
    setWatermarkingDialogMessage(`
    ${watermarkingDialogMessageBase}
    ${watermarkingDialogMessageConfirmation}
  `);
    setWatermarkConfirmationOpen(true);
  };

  const openVideoProxy = {
    released: {
      'asset-preview': (assets: any) => {
        dispatch(updateSelectedPreview(assets[0]));
        dispatch(updatePreviewOpen(true));
      },
      'create-subclip': (assets: any) => {
        navigate('create-subclip', {
          state: { selectedRows: assets },
        });
      },
    },
    watermarked: {
      'asset-preview': (assets: any, videoProxyUrl: string) => {
        dispatch(setWatermarkedUrl(videoProxyUrl));
        dispatch(updateSelectedPreview(assets[0]));
        dispatch(updatePreviewOpen(true));
      },
      'create-subclip': (assets: any, videoProxyUrl: string) => {
        navigate('create-subclip', {
          state: { selectedRows: assets, videoProxyUrl },
        });
      },
    },
    pmam: {
      'create-subclip': (assets: any) => {
        navigate('create-subclip', {
          state: { selectedRows: assets },
        });
      },
    },
  };

  const getVideoMetadata = async (selectedAsset: Asset) => {
    await axios.get(`${API_ENDPOINTS.VIDEO_PROXY}/metadata?materialId=${selectedAsset.materialId}&storageSystem=${selectedAsset.storageSystem}`)
      .then((res) => {
        dispatch(setVideoMetaData(res.data));
      });
  };

  const studioSupplyWatermarkingLogic = async (selectedAsset: any, proxyType: string) => {
    if (selectedAsset.mediatorProgress !== 'READY') {
      /**
       * mediatorProgress must equal 'READY' for a video to be playable
       * Show snackbar info: "Proxy is not available and content is not ready to be viewed."
       */
      dispatch(setAlert({ severity: 'warning', message: 'Warning: Proxy is not available and content is not ready to be viewed...' }));
      return;
    }
    if (selectedAsset.proxyAvailable === false) {
      /**
       * proxyAvailable can be anything other then false: true, null or undefined
       * Show snackbar info: "Proxy is not available and content is not ready to be viewed."
       */
      dispatch(setAlert({ severity: 'warning', message: 'Warning: Proxy is not available and content is not ready to be viewed...' }));
      return;
    }

    // Don't perform watermarking logic for PMAM assets
    if (selectedAsset.storageSystem === 'PMAM_MEDIATOR') {
      (openVideoProxy.pmam as any)[proxyType](selectedRows);
      return;
    }
    // get Asset proxy data
    const assetProxyMeta: any = await getAssetProxy(
      selectedAsset.gtmId,
      selectedAsset.storageSystem,
      selectedAsset.materialId,
    );

    const {
      error,
      titleStatus,
      alreadyWatermarked,
      watermarkingInProgress,
    } = assetProxyMeta.data;

    switch (true) {
      /** Error from backend or No access */
      case error: {
        console.log('%c > 3.0: SUPPLY Asset: Error please contact support.');
        setSnackbarMessage(`Error: ${error}`);
        break;
      }
      /** Asset titleStatus Released, Watermarking is not required */
      case titleStatus === 'Released' && selectedAsset.storageSystem === 'MEDIATOR_8': {
        console.log(`%c > 3.0: SUPPLY Asset has been released: No watermarking required, opening Studio Supply proxy directly: ${selectedAsset.materialId}`, consoleColor);
        // request metadata for mediator 8 proxy to pass audio-tracks
        await getVideoMetadata(selectedAsset);

        const assets = proxyType === 'asset-preview' ? [selectedAsset] : selectedRows;
        (openVideoProxy.released as any)[proxyType](assets);
        break;
      }
      /** Asset titleStatus Released, Watermarking is not required */
      case titleStatus === 'Released' && selectedAsset.storageSystem === 'MEDIATOR_X': {
        console.log(`%c > 3.0: SUPPLY Asset has been released: No watermarking required, opening Studio Supply proxy directly: ${selectedAsset.materialId}`, consoleColor);
        await getVideoMetadata(selectedAsset);

        const assets = proxyType === 'asset-preview' ? [selectedAsset] : selectedRows;
        (openVideoProxy.released as any)[proxyType](assets);
        break;
      }
      /** Asset titleStatus Not Released, Watermarking in progress */
      case watermarkingInProgress: {
        console.log(`%c > 3.0: SUPPLY Asset: Watermarking in progress: ${selectedAsset.materialId}`);
        dispatch(setAlert({ severity: 'warning', message: `Warning: Watermarking order in progress for ${selectedAsset.materialId}.` }));
        break;
      }
      /** Asset titleStatus Not Released, Already watermarked */
      case alreadyWatermarked: {
        /**
         * Add the watermarking url and necessary params to request a watermarked video
         * Last /null is for the m3u8 subfolder on the follow up queries performed by the player
         */
        const videoProxyUrl = `${API_ENDPOINTS.WM_VIDEO_PROXY}/${selectedAsset.gtmId}/${selectedAsset.materialId}/${selectedAsset.storageSystem}/null/null`;

        await getVideoMetadata(selectedAsset);

        const assets = proxyType === 'asset-preview' ? [selectedAsset] : selectedRows;
        (openVideoProxy.watermarked as any)[proxyType](assets, videoProxyUrl);
        break;
      }
      /** Watermarking required */
      default: {
        console.log(`%c > 3.0: SUPPLY Asset: Watermarking required: ${selectedAsset.materialId}`);
        if (!selectedAsset.productionNumber) {
          dispatch(setAlert({ severity: 'warning', message: 'Warning: This asset requires watermarking but it cannot be watermarked because the asset has no production number. Please contact support.' }));
          break;
        }
        promptFortWaterMarking();
      }
    }
  };

  /**
   * Open preview dialog
   * Determine the asset type: PMAM, Supply or Image
   */
  const openPreview = async (data: Asset) => {
    const selectedAsset = data;
    console.log('> Preview asset:', selectedAsset);
    setPreviewAsset(selectedAsset);
    const isPmamAsset = selectedAsset.sourceSystem === SourceSystem.PMAM;
    const isSupplyAsset = selectedAsset.sourceSystem === SourceSystem.SUPPLY;
    const isImage = selectedAsset.mediaType === 'IMAGE' || extensionHasImage(selectedAsset);
    // TODO: move isPmamAsset into isPmamAsset case.
    if (isPmamAsset) {
      if (selectedAsset.pmam?.restrictedStatus === 'RESTRICTED') {
        setRestrictedAssetDialogMessage(restrictedAssetDialogMessageBase);
        setRestrictedAssetConfirmationOpen(true);
        return false;
      }
    }

    switch (true) {
      /** Image assets */
      case isImage: {
        dispatch(updateSelectedPreview(selectedAsset));
        dispatch(updatePreviewOpen(selectedAsset));
        return;
      }

      /* PMAM Assets */
      case isPmamAsset: {
        const canProceed = await canViewPmamAsset(selectedAsset);
        if (canProceed) {
          dispatch(updateSelectedPreview(selectedAsset));
          dispatch(updatePreviewOpen(true));
        }
        return;
      }

      /**
       * SUPPLY Assets
       */
      case isSupplyAsset: {
        studioSupplyWatermarkingLogic(selectedAsset, 'asset-preview');
        return;
      }
      default: {
        console.warn('Unexpected Asset Source system @ TableWrapper.openPreview: ', selectedAsset.sourceSystem);
      }
    }
  };

  const openPropertiesDrawer = (data: any) => {
    switch (type) {
      case 'assets':
      case 'collectionAssets': {
        dispatch(updateSelectedAsset(data));
        toggleProperties(data ? true : false);
        break;
      }
      case 'workOrders': {
        dispatch(setSelectedWorkOrder(data));
        toggleProperties(data ? true : false);
        break;
      }
      default:
        // do nothing
    }
  };

  const cellClicked = (event: any) => {
    const proxyAsset = { ...event.data };
    const { mediaType, proxyAvailable } = proxyAsset;
    const { headerName: columnName } = event.colDef;

    switch (true) {
      // Note: onCellClicked is used in the CollectionsLanding.tsx component.
      case !!onCellClicked:
        onCellClicked(proxyAsset);
        break;
      case columnName === 'Preview' && mediaType !== 'VIDEO':
        openPreview(proxyAsset);
        break;
      // Note: Preview video proxies will be played when proxyAvailable is true or null ot undefined.
      case columnName === 'Preview' && mediaType === 'VIDEO' && proxyAvailable !== false:
        openPreview(proxyAsset);
        break;
      default:
        openPropertiesDrawer(proxyAsset);
    }
  };

  const onGridReady = (params: any) => {
    const columnApi = params.columnApi;
    columnApi.getAllColumns().forEach((col: any) => {
      if (col.userProvidedColDef?.autoSize) {
        columnApi.autoSizeColumn(col.colId);
      }
    });
  };

  const onSortChanged = (event: any): any => {
    const cols = event.columnApi.getColumnState();
    const colSorting = cols.find((c: any) => c.sort);
    const apiSortFelid = type === 'workOrders' ? mapKeyToApiField(colSorting.colId) : colSorting.colId;
    const param = encodeURI(`${apiSortFelid}${colSorting.sort === 'asc' ? '+' : '-'}`);
    updateTableSortingOrder(param);
  };

  const sendToVPostDisabled = useCallback(() => {
    let disabled = false;

    if (!selectedRows?.length) return true;

    const sourceSystems = selectedRows.map((data) => data.sourceSystem);
    sourceSystems.forEach((sourceSystem) => {
      if (sourceSystem !== 'SUPPLY') {
        disabled = true;
      }
    });

    return disabled;
  }, [selectedRows]);

  const markedForDeleteMessage = useCallback(() => {
    if (selectedRows.some((row) => row.pmam?.markedForDelete)) {
      return 'You can\'t perform this action on assets that are marked for deletion';
    }

    return '';
  }, [selectedRows]);

  const checkSendToMerlinAvailability = () => {
    const validExtensions = ['jpeg', 'jpg', 'png'];
    const validProductionTypes = ['key art', 'iconic', 'immersive highlight', 'title treatment', 'episodic thumbnail', 'clip/trailer thumbnail'];

    const isInvalid = selectedRows.some((row) => (
      row?.brand !== 'SkyShowtime'
      || row?.gtmVersionUnifiedId === null
      || row?.aspectRatio === ''
      || row.pmam?.language === null
      || row?.productionType === null
      || row?.pmam?.restrictedStatus === 'RESTRICTED'
      || !validExtensions.includes(row?.pmam?.fileExtension?.toLowerCase())
      || !validProductionTypes.includes(row?.productionType?.toLowerCase())
    ));

    // sets list of missing requirements for each asset to be displayed in tooltip
    const setTooltipMessage = (rows: any[]) => {
      const reqs = new Map();
      const addReqs = (req: string, row: any) => {
        if (reqs.has(row.materialId)) {
          reqs.get(row.materialId).push(req);
        } else {
          reqs.set(row.materialId, [req]);
        }
        setMerlinTooltipRequirements(reqs);
      };
      rows.forEach((row) => {
        let req;
        if (row?.pmam?.restrictedStatus === 'RESTRICTED') {
          req = 'Asset has restrictions';
          addReqs(req, row);
        }
        if (row?.brand !== 'SkyShowtime') {
          req = 'Brand must be SkyShowtime';
          addReqs(req, row);
        }
        if (row?.gtmVersionUnifiedId === null) {
          req = 'GTM Unified Version ID must be present';
          addReqs(req, row);
        }
        if (row?.aspectRatio === '') {
          req = 'Aspect Ratio must be present';
          addReqs(req, row);
        }
        if (!row?.pmam?.language) {
          req = 'Language must be present';
          addReqs(req, row);
        }
        if (!row?.productionType) {
          req = 'Production type must be present';
          addReqs(req, row);
        }
        if (!validExtensions.includes(row?.pmam?.fileExtension?.toLowerCase())) {
          req = 'Merlin delivery is restricted to jpegs and pngs';
          addReqs(req, row);
        }
        if (!validProductionTypes.includes(row?.productionType?.toLowerCase())) {
          req = 'Production Type not supported by Merlin';
          addReqs(req, row);
        }
      });
    };

    if (isInvalid) {
      // asset is missing requirements
      setTooltipMessage(selectedRows);
    } else {
      // asset fulfills all requirements
      setMerlinTooltipRequirements(null);
    }
    setMerlinIsDisabled(isInvalid);
  };

  const extractTimeInSeconds = (timeString: string) => {
    const timeParts = timeString.split(':');
    const hours = parseInt(timeParts[0], 10);
    const minutes = parseInt(timeParts[1], 10);
    const seconds = parseInt(timeParts[2], 10);
    const milliseconds = parseInt(timeParts[3], 10);

    const totalMilliseconds = hours * 3600000 // 1 hour = 3600000 milliseconds
      + minutes * 60000 // 1 minute = 60000 milliseconds
      + seconds * 1000 // 1 second = 1000 milliseconds
      + milliseconds;
    return Math.floor(totalMilliseconds / 1000);
  };

  const checkFieldExists = (asset: any, fieldName: string, path: string[]): any => {
    if (path.length) {
      const nestedField = path[0];
      if (!asset[nestedField]) return null;
      return checkFieldExists(asset[nestedField], fieldName, path.slice(1));
    }
    return asset[fieldName];
  };

  const handleAddToMap = (map: Record<string, string[]>, title: string, mapId: string) => {
    if (map[mapId]) {
      map[mapId].push(title);
    } else {
      map[mapId] = [title];
    }
  };

  const checkApproveAvailability = () => {
    const assetsThatCanBeApproved: any[] = [];
    const requirementErrors: Record<string, string[]> = {};
    const fieldsNeedToExist = [
      { fieldName: 'duration', displayName: 'Asset technical spec duration', path: [] },
      { fieldName: 'producer', displayName: 'Producer', path: ['pmam', 'airtable'] },
      { fieldName: 'tag', displayName: 'Tag', path: ['pmam', 'airtable'] },
      { fieldName: 'project', displayName: 'Project', path: ['pmam', 'airtable'] },
      { fieldName: 'deliverableName', displayName: 'Deliverable Name', path: ['pmam', 'airtable'] },
      { fieldName: 'promoCode', displayName: 'Promo Code', path: ['pmam', 'airtable'] },
      { fieldName: 'houseNumber', displayName: 'House ID', path: ['pmam', 'airtable'] },
      { fieldName: 'description', displayName: 'Version Type', path: ['pmam', 'airtable', 'versionType'] },
      { fieldName: 'productionType', displayName: 'Production Type', path: [] },
      { fieldName: 'revisionNumber', displayName: 'Revision Number', path: ['pmam', 'airtable'] },
      { fieldName: 'shipDate', displayName: 'Ship Date', path: ['pmam', 'airtable'] },
      { fieldName: 'startAir', displayName: 'Start Air', path: ['pmam', 'airtable'] },
      { fieldName: 'lengthInSeconds', displayName: 'Length', path: ['pmam', 'airtable'] },
    ];

    Promise.all(selectedRows.map((row) => axios.get<PmamAsset>(`${API_ENDPOINTS.PMAM_ASSET}/${row.pmam?.assetId}`))).then((all) => {
      selectedRows.forEach((row: any, index: number) => {
        const rowData = cloneDeep(row);
        rowData.pmam.airtable = all[index].data.airtable;
        let canApproveAsset = true;
        const mapId = rowData.materialId;
        fieldsNeedToExist.forEach((f) => {
          const fieldValue = checkFieldExists(rowData, f.fieldName, f.path);
          if (!fieldValue) {
            canApproveAsset = false;
            const title = `${f.displayName} value is missing from asset`;
            handleAddToMap(requirementErrors, title, mapId);
          }
        });
        const assetLengthOnSec = extractTimeInSeconds(rowData.duration);
        const airTableLengthIsAssetLength = assetLengthOnSec === rowData.pmam?.airtable?.lengthInSeconds;
        if (!airTableLengthIsAssetLength) {
          canApproveAsset = false;
          const title = 'Airtable duration does not match with asset duration';
          handleAddToMap(requirementErrors, title, mapId);
        }
        if (canApproveAsset) assetsThatCanBeApproved.push(row);
      });
      if (Object.keys(requirementErrors).length > 0) {
        setApproveAssetErrorList(requirementErrors);
        return;
      }
      // return assetsThatCanBeApproved;
      if (!assetsThatCanBeApproved.length) return;
      const ids = assetsThatCanBeApproved.map((row) => row.pmam.assetId);
      const status = 'APPROVED';
      const body = { ids, status, comment: '' };
      axios.post(API_ENDPOINTS.TOGGLE_APPROVE_ASSETS, body).then(() => {
        const payLoad = selectedRows.map((row) => ({
          id: row.pmam.assetId,
          rowData: {
            ...row,
            pmam: { ...row.pmam, svApprovalStatus: 'APPROVED' },
          },
        }));
        updateTableData(payLoad);
        setTooltipSnackbarMessage(`Requested asset${ids.length === 1 ? '' : 's'} approved`);
      }).catch((err) => {
        setTooltipSnackbarMessage(`${err}`);
      });
      setAssetMenuAnchor(null);
    });
  };

  const checkIfCanApproveAndReject = () => {
    const assetsNotRequiredApproval = selectedRows.filter((r) => r.pmam?.svApprovalStatus === 'NOT_REQUIRED');
    const restrictedAssets = selectedRows.filter((r) => r.pmam?.restrictedStatus === 'RESTRICTED');
    const studioSupplyAssets = selectedRows.filter((r) => !r.pmam);
    if (studioSupplyAssets.length) {
      const tooltip = (
        <>
          <p> {`The following asset${studioSupplyAssets.length > 1 ? 's' : ''}
          ${studioSupplyAssets.length > 1 ? 'are a' : 'is a'} studio supply asset and cannot be Approved or Rejected`}
          </p>
          {studioSupplyAssets.map((asset) => (
            <Box mt={2} key={asset.materialId}>
              <li>{asset.materialId}</li>
            </Box>
          ))}
        </>
      );
      setCantApproveRejectTooltip(tooltip);
    } else if (restrictedAssets.length) {
      const tooltip = (
        <>
          <p> {`The following asset${restrictedAssets.length > 1 ? 's' : ''}
          ${restrictedAssets.length > 1 ? 'have' : 'has'} restrictions and cannot be Approved or Rejected`}
          </p>
          {restrictedAssets.map((asset) => (
            <Box mt={2} key={asset.materialId}>
              <li>{asset.materialId}</li>
            </Box>
          ))}
        </>
      );
      setCantApproveRejectTooltip(tooltip);
    } else if (assetsNotRequiredApproval.length) {
      const tooltip = (
        <>
          <p> {`The following asset${assetsNotRequiredApproval.length > 1 ? 's' : ''}  cannot be Approved or Rejected because ${assetsNotRequiredApproval.length > 1 ? 'they do' : 'it does'} not require approval`}</p>
          {assetsNotRequiredApproval.map((asset) => (
            <Box mt={2} key={asset.materialId}>
              <li>{asset.materialId}</li>
            </Box>
          ))}
        </>
      );
      setCantApproveRejectTooltip(tooltip);
    } else {
      setCantApproveRejectTooltip(null);
    }
  };

  const checkDisableSubClipping = () => {
    selectedRows.some((row) => {
      switch (true) {
        case row?.pmam?.restrictedStatus === 'RESTRICTED':
          setSubClipError('This asset has restrictions and can\'t be subclipped');
          return true;
        case row?.storageSystem === 'PMAM_MEDIATOR' && !featureFlags.PmamSubclippingEnabled:
          setSubClipError('You cannot subclip assets from PMAM Mediator.');
          return true;
        case row?.mediatorProgress !== 'READY':
          setSubClipError('This material is not in a ready state and can\'t be subclipped. Please contact support.');
          return true;
        case !row?.productionNumber && row?.storageSystem !== 'PMAM_MEDIATOR':
          setSubClipError('There is no production number for this asset. Cannot create a subclip. Please contact support.');
          return true;
        case row.mediaType !== 'VIDEO':
          setSubClipError('You cannot subclip assets which aren\'t videos.');
          return true;
        default:
          setSubClipError('');
          return false;
      }
    });
  };

  useEffect(() => {
    // disables send to Merlin button in kebab menu
    checkDisableSubClipping();
    checkSendToMerlinAvailability();
    checkIfCanApproveAndReject();
    checkUserPolicies();
  }, [selectedRows]);

  const validateRestrictActions = (rows: any) => {
    if (rows.length === 0) {
      return;
    }
    const hasSupplySelected = rows.some((r: any) => r.sourceSystem === 'SUPPLY');
    const restrictedAssets = rows.filter((r: any) => r.pmam?.restrictedStatus === 'RESTRICTED');
    const notRestrictedAssets = rows.filter((r: any) => r.pmam?.restrictedStatus === 'NOT_RESTRICTED');
    const curRestrictedState = restrictedAssets.length ? 'RESTRICTED' : 'NOT_RESTRICTED';
    setRestrictedState(curRestrictedState);
    const selectedAssetsNotAuthorized = rows.filter((r: any) => {
      // Filter out null values for modifyRestriction policies..
      // This fixes an issue where the different environments have different polices
      // The stage environment currently has a null modifyRestriction policy...
      const modifyRestriction = user.policies.asset.modifyRestriction.filter(
        (assetModifyRestriction:any) => Boolean(assetModifyRestriction),
      );
      return !modifyRestriction.map((p: any) => p.description).includes(r.brand);
    });
    if (restrictedAssets.length && notRestrictedAssets.length) {
      // user selected both restricted and non-restricted
      setRestrictedMessage('Cannot have a mixed selection of restricted and not restricted assets.');
      return setRestrictedState(null);
    }
    if (hasSupplySelected) {
      setRestrictedMessage('The selected asset is a "Supply" asset only "PMAM" assets can be restricted.');
      return setRestrictedState(null);
    }
    if (
      selectedAssetsNotAuthorized.length
    ) {
      setRestrictedMessage(`
      You are not authorized to change the restricted status of assets of
      ${selectedAssetsNotAuthorized.map((a: any) => a.materialId).join(', ')}
      `);
      return setRestrictedState(null);
    }
  };

  const onSelectionChange = (event: any) => {
    const rows = event.api.getSelectedRows();
    let lastSelectedRow = event.api?.selectionService?.lastSelectedNode?.data;
    if (lastSelectedRow) {
      if (!rows.find((node: Asset) => node.id === lastSelectedRow?.id)) {
        lastSelectedRow = rows[rows.length - 1];
      }
      openPropertiesDrawer(lastSelectedRow);
    }

    validateRestrictActions(rows);
    setSelectedRows(rows);
    updateSelectedData(rows);
    dispatch(updateItems(rows));
    return event;
  };

  const renderCardView = (): any => (
    <Box sx={{ ...classes.cardViewContainer }}>
      {tableData.map((data, _index) => (
        <NbcuMediaCard
          duration={data.duration}
          key={`nbcu-card-${data.id}`}
          onTitleClicked={() => {
            cellClicked({ data });
          }}
          onMediaPreviewClicked={() => {
            openPreview({ data });
          }}
          title={data.title}
          subtitle={data.pmam?.fileName}
          caption={data.pmam?.fileSize}
          mediaType={data.mediaType}
        >
          <MediaThumbnail asset={data} size={MediaSize.ICON} />
        </NbcuMediaCard>
      ))}
    </Box>
  );

  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());

  /** Open asset menu on click */
  const openAssetMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAssetMenuAnchor(event.currentTarget);
  };

  /** Close asset menu */
  const closeAssetMenu = () => {
    setAssetMenuAnchor(null);
  };

  const goToVPostOrder = () => {
    navigate('/assets/vpost-order-assets', {});
  };

  /** Got to create subclip */
  const goToCreateSubclip = async () => {
    const selectedAsset = selectedRows[0];
    studioSupplyWatermarkingLogic(selectedAsset, 'create-subclip');
  };

  const getSubclipTooltip = () => {
    let error = subClipError;
    if (selectedRows.length > 1) {
      error = 'You cannot create a subclip for more than one source.';
    }
    return error;
  };

  const sendToMerlin = () => {
    const assetIds = selectedRows.map((row) => Number(row.pmam.assetId));
    axios.post(API_ENDPOINTS.MERLIN, assetIds).then(() => {
      setTooltipSnackbarMessage('Merlin delivery requested');
    }).catch((err) => {
      setTooltipSnackbarMessage(`${err}`);
    });
    setAssetMenuAnchor(null);
  };

  const submitRejectReason = () => {
    const ids = selectedRows.map((row) => row.pmam.assetId);
    const status = 'REJECTED';
    const body = {
      comment: rejectReason,
      ids,
      status,
    };
    axios.post(API_ENDPOINTS.TOGGLE_APPROVE_ASSETS, body).then(() => {
      const payLoad = selectedRows.map((row) => ({
        id: row.pmam.assetId,
        rowData: {
          ...row,
          pmam: { ...row.pmam, svApprovalStatus: 'REJECTED' },
        },
      }));
      updateTableData(payLoad);
      setSnackbarMessage('Reject Reason submitted');
      setRejectReason('');
      setRejectReasonInvalid(false);
      setShowRejectModal(false);
    }).catch((err) => {
      setSnackbarMessage(`${err}`);
    });
  };

  const submitRestrictReason = (status: string) => {
    const assetIds = selectedRows.map((row) => row.pmam.assetId);
    const body = {
      restrictReason,
      assetIds,
      status,
    };
    axios.post(API_ENDPOINTS.SET_BULK_RESTRICTED_STATUS, body).then(() => {
      const payLoad = selectedRows.map((row) => ({
        id: row.pmam.assetId,
        rowData: { ...row, pmam: { ...row.pmam, restrictedStatus: status } },
      }));
      updateTableData(payLoad);
      setSnackbarMessage('Restricted Reason submitted');
      setRestrictReason('');
      setShowRestrictModal(false);
    }).catch((err) => {
      setSnackbarMessage(`${err}`);
    });
  };

  const renderErrorList = (errorList: Record<string, string[]> | Map<string, string[]>) => (
    <Box>
      {errorList && Object.entries(errorList)
        .map(([key, values]) => (
          <Box mt={2} key={key}>
            {key}: <ul>{values.map((val: string) => <li key={val}>{val}</li>)}</ul>
          </Box>
        ))}
    </Box>
  );

  const generateTooltip = (tooltipTitle: string, errorListInfo: Map<string, string[]>) => {
    const listAsObject = Object.fromEntries(Array.from(errorListInfo.entries()));
    return (
      <>
        <Box>{tooltipTitle}</Box>
        {renderErrorList(listAsObject)}
      </>
    );
  };

  const onUpdateTableViewChange = (view: any) => {
    if (view === 'NORMAL' || view === 'COMFORTABLE') {
      setRowDensity(view.toLowerCase());
    }
    setTableView(view);
  };

  const onCopyMaterialIdsClink = () => {
    const materialIds = selectedRows.map((row: any) => row.materialId).join(', ');
    navigator.clipboard.writeText(materialIds).then(() => {
      dispatch(setAlert({ severity: 'info', message: 'Material IDs have been copied to the clipboard.' }));
      closeAssetMenu();
    }).catch(() => {
      dispatch(setAlert({ severity: 'error', message: 'Error: No Material IDs have been copied.' }));
    });
  };

  const onDeleteClose = (confirm: boolean) => {
    if (confirm) {
      axios.post(API_ENDPOINTS.DELETE_ASSETS, selectedRows.map((row: Asset) => Number(row.pmam.assetId))).then(() => {
        dispatch(setAlert({ severity: 'success', message: 'Assets have been marked for deletion' }));
        const payload = selectedRows.map((row: Asset) => ({
          id: row.pmam.assetId,
          rowData: {
            ...row,
            pmam: {
              ...row.pmam,
              markedForDelete: true,
            },
          },
        }));
        updateTableData(payload);
        setShowDeleteModal(false);
      });
      return;
    }

    setShowDeleteModal(false);
  };

  return (
    <Box sx={{ ...classes.root }} className={`table-wrapper-${type}`} data-testid="table-wrapper">
      {
        showRejectModal && (
          <Box className="reject-assets-container">
            <mui.Card className="reject-assets-card">
              <mui.CardContent>
                <Box style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  borderBottom: '1px solid #cdcdcd',
                  paddingBottom: '16px',
                  marginBottom: '16px',
                }}
                >
                  <h3>Reject Asset</h3>
                  <Box
                    onClick={() => {
                      setRejectReason('');
                      setRejectReasonInvalid(false);
                      setShowRejectModal(false);
                    }}
                    className="close-icon"
                  >
                    <icons.Close />
                  </Box>
                </Box>
                <Box>
                  <mui.TextField
                    className="form-input"
                    error={rejectReasonInvalid}
                    variant="outlined"
                    placeholder="Reason for Rejection"
                    value={rejectReason}
                    onChange={(e) => {
                      const text = e.target.value;
                      setRejectReason(text);
                      setRejectReasonInvalid(text.length > 300);
                    }}
                  />
                </Box>
                <Box
                  component="span"
                  className="comment-length"
                  style={{ marginTop: '5px', display: 'flex', justifyContent: 'flex-end' }}
                >
                  {rejectReason.length}
                  /300
                </Box>
                <Box style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  borderTop: '1px solid #cdcdcd',
                  paddingTop: '16px',
                  marginTop: '16px',
                }}
                >
                  <NbcuButton
                    disabled={rejectReasonInvalid}
                    onClick={() => {
                      submitRejectReason();
                    }}
                    variant="contained"
                  >
                    Reject
                  </NbcuButton>
                </Box>
              </mui.CardContent>
            </mui.Card>
          </Box>
        )
      }
      {
        showRestrictModal && (
          <Box className="reject-assets-container">
            <mui.Card className="reject-assets-card">
              <mui.CardContent>
                <Box style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  borderBottom: '1px solid #cdcdcd',
                  paddingBottom: '16px',
                  marginBottom: '16px',
                }}
                >
                  <h3>{restrictedState === 'RESTRICTED' ? 'Unrestricted' : 'Restricted'} Reason</h3>
                  <Box
                    onClick={() => {
                      setRestrictReason('');
                      setShowRestrictModal(false);
                    }}
                    className="close-icon"
                  >
                    <icons.Close />
                  </Box>
                </Box>
                <Box>
                  <mui.TextField
                    className="form-input"
                    error={restrictReason.length > characterLimit}
                    placeholder={restrictedState === 'RESTRICTED' ? 'Unrestricted Reason' : 'Restricted Reason'}
                    value={restrictReason}
                    onChange={(e) => {
                      setRestrictReason(e.target.value);
                    }}
                  />
                </Box>
                <Box
                  component="span"
                  className="comment-length"
                  style={{ marginTop: '5px', display: 'flex', justifyContent: 'flex-end' }}
                >
                  {restrictReason.length}
                  /1970
                </Box>
                <Box style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  borderTop: '1px solid #cdcdcd',
                  paddingTop: '16px',
                  marginTop: '16px',
                  gap: '8px',
                }}
                >
                  <NbcuButton
                    variant="outlined"
                    onClick={() => {
                      setRestrictReason('');
                      setShowRestrictModal(false);
                    }}
                  >Cancel
                  </NbcuButton>
                  <NbcuButton
                    disabled={!restrictReason || restrictReason.length > characterLimit}
                    onClick={() => {
                      submitRestrictReason(restrictedState === 'RESTRICTED' ? 'NOT_RESTRICTED' : 'RESTRICTED');
                    }}
                    variant="contained"
                  >
                    {restrictedState === 'RESTRICTED' ? 'Unrestrict' : 'Restrict'}
                  </NbcuButton>
                </Box>
              </mui.CardContent>
            </mui.Card>
          </Box>
        )
      }
      {
        type !== 'addToCollection' && (
          <Grid container className="table-top">
            <Grid item xs={10} sx={{ ...classes.tableButtons }}>
              {showTableButtons && (
              <>
                <TableButtons
                  type={type}
                  buttonActions={buttonActions}
                  selectedRows={selectedRows}
                />
                {(type === 'assets' || type === 'collectionDetails' || type === 'collectionAssets') && (
                  <>
                    <NbcuButton
                      disabled={selectedRows.length === 0}
                      onClick={openAssetMenu}
                      startIcon={<icons.MoreVert />}
                      sx={{ ...classes.assetMenuButton }}
                    />
                    <mui.Menu
                      id="TableWrapper_menu"
                      open={Boolean(assetMenuAnchor)}
                      anchorEl={assetMenuAnchor}
                      onClose={closeAssetMenu}
                    >
                      <NbcuTooltip
                        title=""
                        placement="right-end"
                        leaveDelay={300}
                        disableFocusListener
                        disabled
                      >
                        <NbcuMenuItem
                          id="tableWrapper_copy-material-ids"
                          onClick={() => onCopyMaterialIdsClink()}
                          disabled={false}
                        >
                          Copy Material IDs
                        </NbcuMenuItem>
                      </NbcuTooltip>
                      <NbcuTooltip
                        title={markedForDeleteMessage() || restrictedMessage}
                        placement="right-end"
                        leaveDelay={300}
                        disableFocusListener
                        disabled={restrictedState !== null && !markedForDeleteMessage()}
                      >
                        <>
                          <NbcuMenuItem
                            id="tableWrapper_assetMenu-restricted"
                            disabled={
                              !!markedForDeleteMessage() || restrictedState !== 'NOT_RESTRICTED'
                            }
                            onClick={() => {
                              setShowRestrictModal(true);
                              closeAssetMenu();
                            }}
                          >
                            Restricted
                          </NbcuMenuItem>
                          <NbcuMenuItem
                            id="tableWrapper_assetMenu-not-restricted"
                            disabled={
                                  (restrictedState !== 'RESTRICTED')
                                }
                            onClick={() => {
                              setShowRestrictModal(true);
                              closeAssetMenu();
                            }}
                          >
                            Not Restricted
                          </NbcuMenuItem>
                        </>
                      </NbcuTooltip>
                      {
                      featureFlags.sendToVPost && (
                        <NbcuTooltip
                          title={markedForDeleteMessage() || 'This action is only for Supply assets.'}
                          placement="right-end"
                          leaveDelay={300}
                          disableFocusListener
                          disabled={!sendToVPostDisabled() && !markedForDeleteMessage()}
                        >
                          <NbcuMenuItem
                            id="tableWrapper_send-to-vpost"
                            onClick={goToVPostOrder}
                            disabled={sendToVPostDisabled() || !!markedForDeleteMessage()}
                          >
                            Send To VPost
                          </NbcuMenuItem>
                        </NbcuTooltip>
                      )
                    }
                      <NbcuTooltip
                        title={markedForDeleteMessage()}
                        placement="right-end"
                        leaveDelay={300}
                        disableFocusListener
                        disabled={!markedForDeleteMessage()}
                      >
                        <NbcuMenuItem
                          id="tableWrapper_assetMenu-activate"
                          disabled
                        >
                          Activate
                        </NbcuMenuItem>
                      </NbcuTooltip>
                      <NbcuTooltip
                        title={markedForDeleteMessage()}
                        placement="right-end"
                        leaveDelay={300}
                        disableFocusListener
                        disabled={!markedForDeleteMessage()}
                      >
                        <NbcuMenuItem
                          id="tableWrapper_assetMenu-deactivate"
                          disabled
                        >
                          Deactivate
                        </NbcuMenuItem>
                      </NbcuTooltip>
                      <NbcuTooltip
                        title={deleteDisabledMessage}
                        placement="right-end"
                        leaveDelay={300}
                        disableFocusListener
                        disabled={!deleteDisabledMessage}
                      >
                        <NbcuMenuItem
                          id="tableWrapper_assetMenu-delete"
                          onClick={() => setShowDeleteModal(true)}
                          disabled={deleteIsDisabled || !!markedForDeleteMessage()}
                        >
                          Delete
                        </NbcuMenuItem>
                      </NbcuTooltip>
                      <NbcuTooltip
                        disableFocusListener
                        sx={{
                          '& .MuiTooltip-tooltip': {
                            flexDirection: 'column',
                            padding: 2,
                          },
                        }}
                        title={markedForDeleteMessage() || getSubclipTooltip()}
                      >
                        <NbcuMenuItem
                          id="tableWrapper_assetMenu-generateSubclip"
                          onClick={goToCreateSubclip}
                          disabled={!!getSubclipTooltip() || !!markedForDeleteMessage()}
                        >
                          Create Subclip
                        </NbcuMenuItem>
                      </NbcuTooltip>
                      <NbcuTooltip
                        title={markedForDeleteMessage()}
                        placement="right-end"
                        leaveDelay={300}
                        disableFocusListener
                        disabled={!markedForDeleteMessage()}
                      >
                        <NbcuMenuItem
                          id="tableWrapper_assetMenu-quickRetrieve"
                          disabled
                        >
                          Quick Retrieve
                        </NbcuMenuItem>
                      </NbcuTooltip>
                      <NbcuTooltip
                        disableFocusListener
                        sx={{
                          '& .MuiTooltip-tooltip': {
                            flexDirection: 'column',
                            padding: 2,
                          },
                        }}
                        title={markedForDeleteMessage() || (merlinTooltipRequirements
                          ? generateTooltip(merlinTooltipMessage, merlinTooltipRequirements)
                          : '')}
                      >
                        <NbcuMenuItem
                          id="tableWrapper_assetMenu-sendToMerlin"
                          disabled={!!markedForDeleteMessage() || merlinIsDisabled}
                          onClick={sendToMerlin}
                        >
                          Send to Merlin
                        </NbcuMenuItem>
                      </NbcuTooltip>
                      <NbcuTooltip
                        disableFocusListener
                        sx={{
                          '& .MuiTooltip-tooltip': {
                            flexDirection: 'column',
                            padding: 2,
                          },
                        }}
                        title={markedForDeleteMessage() || cantApproveRejectTooltip}
                      >
                        <NbcuMenuItem
                          disabled={!!markedForDeleteMessage() || !!cantApproveRejectTooltip}
                          id="tableWrapper_assetMenu-approve"
                          onClick={checkApproveAvailability}
                        >
                          Approve
                        </NbcuMenuItem>
                      </NbcuTooltip>
                      <NbcuTooltip
                        disableFocusListener
                        sx={{
                          '& .MuiTooltip-tooltip': {
                            flexDirection: 'column',
                            padding: 2,
                          },
                        }}
                        title={markedForDeleteMessage() || cantApproveRejectTooltip}
                      >
                        <NbcuMenuItem
                          onClick={() => {
                            setShowRejectModal(true);
                            closeAssetMenu();
                          }}
                          disabled={!!markedForDeleteMessage() || !!cantApproveRejectTooltip}
                          id="tableWrapper_assetMenu-reject"
                        >
                          Reject
                        </NbcuMenuItem>
                      </NbcuTooltip>
                    </mui.Menu>
                  </>
                )}
              </>
              )}
            </Grid>
            {showTableOptions && (
            <Grid item xs={2}>
              <TableOptions
                selected={tableView}
                hideCard={hideCard}
                hideList={hideList}
                hideViewList={hideViewList}
                hideSettings={hideSettings}
                updateTableView={onUpdateTableViewChange}
              />
            </Grid>
            )}
          </Grid>
        )
      }
      <Box className={`table-contained table-${type}`}>
        {innerContent}
        <Box className="table">
          {tableView !== TableViews.CARD
            && tableColumns?.length > 0
            && tableData?.length > 0 && (
            <NbcuTable
              autoSizeAllColumns={false}
              pagination={false}
              columns={tableColumns}
              rowData={tableData}
              rowDensity={rowDensity}
              onSortChanged={onSortChanged}
              onCellClicked={cellClicked as any}
              onGridReady={onGridReady}
              onSelectionChanged={onSelectionChange}
            />
          )}
          {tableView === TableViews.CARD && tableData?.length && renderCardView()}
        </Box>
      </Box>
      <Box className="paginator">
        <mui.TablePagination
          data-testid="table-pagination"
          component="div"
          count={tableCount}
          page={tablePage - 1}
          onPageChange={(e: React.MouseEvent<HTMLButtonElement> | null, n: number) => handleChangePage(e, n)}
          rowsPerPage={tableLimit}
          onRowsPerPageChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChangeRowsPerPage(e)}
          backIconButtonProps={{ 'data-testid': 'table-pagination-back-button' } as IconButtonPropsWithTestId}
          nextIconButtonProps={{ 'data-testid': 'table-pagination-next-button' } as IconButtonPropsWithTestId}
        />
        <input
          type="text"
          defaultValue={params.page || 1}
          onKeyUp={(e: any) => {
            if (e.keyCode === 13) {
              let val = +e.target.value;
              const limit = Math.ceil(tableCount / tableLimit);

              if (Number.isNaN(val)) {
                val = 1;
              } else if (val > limit) {
                val = limit;
              }

              handleChangePage(null, +val - 1);
            }
          }}
          maxLength={4}
          className="page-number"
        />
      </Box>
      <NbcuModal
        open={!!Object.keys(approveAssetErrorList).length}
        title="The following assets can not be approved"
        primaryLabel="Dismiss" // Use for Accept button text
        paneOne={renderErrorList(approveAssetErrorList)}
        onModalClosed={(_) => setApproveAssetErrorList({})}
        secondaryLabel=""
      />
      <NbcuDialog
        title="Restricted Asset"
        bodyText={restrictedAssetDialogMessage}
        open={restrictedAssetConfirmationOpen}
        onDialogClosed={onRestrictedAssetConfirmationClose}
      />
      <NbcuDialog
        title="Delete Asset"
        bodyText="Are you sure you want to delete this asset?"
        open={showDeleteModal}
        onDialogClosed={onDeleteClose}
        showCancelButton
      />
      <NbcuDialog
        title="Preview Asset"
        bodyText={watermarkingDialogMessage}
        open={watermarkConfirmationOpen}
        onDialogClosed={onWatermarkingConfirmationClose}
        showCancelButton
      />
      <NbcuSnackbar
        id="merlin_delivery"
        message={tooltipSnackbarMessage}
        open={Boolean(tooltipSnackbarMessage)}
        onClose={() => setTooltipSnackbarMessage('')}
      />
      <NbcuSnackbar
        id="preview_noProxyAlert"
        message={snackbarMessage}
        open={Boolean(snackbarMessage)}
        onClose={() => setSnackbarMessage('')}
        sx={{ '& .MuiSnackbarContent-root': { minWidth: '300px' } }}
        autoHideDuration={autoHideDuration}
      />
    </Box>
  );
}
