import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import { Box, mui, NbcuChip } from 'tl-storybook';
import axios from 'axios';
import { useNavigate, useLocation } from 'react-router-dom';
import { useQueryParams } from 'use-query-params';
import { DEFAULT_ASSET_COLUMNS, ALL_ASSET_COLUMNS } from '../../../constants/ASSET_COLUMNS';
import {
  DrawerSortingService, deleteNestedValue, formatAspectRatio, formatFieldNames,
} from '../../../core/utils';
import FilterIcon from '../../../core/components/app/FilterIcon';
import SearchInput from '../../../core/components/app/SearchInput';
import ConfigureColumnDrawer from '../../../core/components/ConfigureColumnsDrawer';
import FilterDrawer, { TAB } from '../../../core/components/FilterDrawer';
import PropertiesDrawer from '../../../core/components/PropertiesDrawer';
import PropertiesTabs from '../PropertiesDrawer/PropertiesTabs';
import TableWrapper from '../../../core/components/Table/TableWrapper';
import { API_ENDPOINTS } from '../../../core/consts/API_ENDPOINTS';
import { CONTEXT } from '../../../core/consts/CONTEXT';
import { EXACT_FILTERS, KEYWORD_CONTAINS_TYPES, WORKORDERS_CONTAINS_FILTERS } from '../../../core/consts/FILTER_ITEM_TYPES';
import { updateErrorMessage, updateErrorOpen } from '../../../core/slices/ErrorSlice';
import { setLoadingSpinner } from '../../../core/slices/UserFeedBackSlice';
import { formatDuration } from '../../../core/helpers';
import { Asset } from '../../../interfaces/asset';
import { ConfigureItem } from '../../../interfaces/configure-drawer-item';
import { DrawerFieldResponse, FilterDrawerValue } from '../../../interfaces/drawer-item';
import { SavedFilter } from '../../../interfaces/saved-filter';
import { LookupResponse, SearchableField } from '../../../interfaces/searchable-field';
import { ASSETS_QUERY_PARAMETERS } from '../../consts/AssetsQueryParameters';
import { useAppSelector, useAppDispatch } from '../../../interfaces/hooks';
import { setSavedAssets } from '../EditAssets/EditAssetsSlice';
import {
  translateQueryParamsToFilters,
  doUpdateSavedFilters,
  setSavedFilters,
  updateSelectedFilters,
  setFilterObject,
  updateFilterObject,
  setFilterToEdit,
  setConfirmRemoveFilter,
} from '../../../core/slices/FilterSlice';
import {
  setPropertiesDrawerType,
  setSelectedAsset,
} from '../../../core/slices/PropertiesDrawerSlice';
import { updateSelectedColumns } from '../../../core/slices/ConfigureDrawerSlice';
import { optimisticallyUpdateData } from './utils';
import { formatSupplyOptions } from '../../../studio-supply-orders/const/dropdown-options';
import { useBrandsFilter } from '../../../hooks/useBrandsFilter';
import ConfirmModal from '../../../core/components/Modal/ConfirmModal';

import { classes } from './AssetsLanding.styles';

export default function AssetsLanding() {
  const dispatch: any = useAppDispatch();

  const loadingSpinner = useAppSelector((state) => state.userFeedBack.loadingSpinner);
  const savedAssets = useAppSelector((state) => state.editAsset.savedAssets);
  const assetsFilters = useAppSelector((state) => state.filter.assetsFilters);
  const selectedAsset = useAppSelector((state) => state.propertiesDrawer.selectedAsset);

  const savedFilters = useAppSelector((state) => state.filter.savedFilters);
  const confirmRemoveFilter = useAppSelector((state) => state.filter.confirmRemoveFilter);

  const { user } = useAppSelector<any>((state) => state.appUser);
  const { networks } = useAppSelector<any>((state) => state.options);
  const { featureFlags } = useAppSelector<any>((state) => state.featureFlags);
  const [filterOpen, setFilterOpen] = useState<boolean>(false);
  const [filterTab, setFilterTab] = useState<TAB>(TAB.FILTER);
  const [tableData, setTableData] = useState<Asset[]>([]);
  const [selectedData, setSelectedData] = useState<any[]>([]);
  const [tableCount, setTableCount] = useState<number>(0);
  const [filterData, setFilterData] = useState<DrawerFieldResponse>();
  const [defaultColumns, setDefaultColumns] = useState<ConfigureItem[]>();
  const [sortedColumns, setSortedColumns] = useState<DrawerFieldResponse>();
  const [columnsNumber, setColumnsNumber] = useState<number>();

  const drawerPageRef = useRef<HTMLDivElement>();
  const initialLoaded = useRef<boolean>(false);

  const [query, setQuery] = useQueryParams(ASSETS_QUERY_PARAMETERS);
  const navigate = useNavigate();
  const location = useLocation();
  const context = CONTEXT.ASSETS;

  const createQueryObj = (
    item: SearchableField,
    filterValue: FilterDrawerValue | string,
    name = item.name,
  ) => {
    let value = filterValue;
    let filterName = name;
    if (filterName.includes('-after') || filterName.includes('-before') || filterName.includes('-gt-iso') || filterName.includes('-lt-iso')) {
      const year = value.toString().split('-')[0];
      if (Number(year) < 1000) {
        return;
      }
      if (window.location.href.includes('work-orders')) {
        value = String(value).split('T')[0];
      }
    }
    const newQueryObj: { [key: string]: FilterDrawerValue } = {};
    // TODO boolean states need design work, making it work as it did for now
    if (value === false) {
      value = undefined;
    }

    if (EXACT_FILTERS.includes(item.name)) {
      filterName += '-exact';
    } else if (WORKORDERS_CONTAINS_FILTERS.includes(item.name)) {
      filterName += '-contains';
    } else if (KEYWORD_CONTAINS_TYPES.includes(item.type)) {
      filterName += '-keywordContains';
    }

    if (item.comparators.includes('CODE')) {
      filterName += '-code';
    }

    newQueryObj[filterName] = Array.isArray(value)
      ? (value as LookupResponse[]).map((i) => (i.id ? i.id : i.code))
      : (value as LookupResponse)?.id || (value as LookupResponse)?.code || value;
    return newQueryObj;
  };

  const catchError = useCallback((error) => {
    dispatch(updateErrorMessage(error));
    dispatch(updateErrorOpen(true));
    dispatch(setLoadingSpinner(false));
  }, []);

  const setUndefinedToEmptyString = (asset: Asset) => {
    const fields = Object.keys(asset);
    fields.forEach((field) => {
      if (asset[field] && typeof asset[field] === 'object') {
        setUndefinedToEmptyString(asset[field]);
      } else if (asset[field] === 'UNDEFINED') {
        asset[field] = '';
      }
    });
  };

  const formatTableData = (tableDataAsset: Asset[]): Asset[] => tableDataAsset.map((asset: Asset) => {
    setUndefinedToEmptyString(asset);
    return {
      ...asset,
      duration: formatDuration(asset.duration),
      totalRunTime: formatDuration(asset.totalRunTime),
      aspectRatio: formatAspectRatio(asset.aspectRatio),
    };
  });

  const filterSortedColumns = (drawerFieldResponse: DrawerFieldResponse) => {
    const valuesToRemove = ['Keyframe Ready', 'Mediator Status', 'Mediator Status Progress'];
    valuesToRemove.forEach((value) => deleteNestedValue(drawerFieldResponse, 'displayName', value));
    drawerFieldResponse.total -= drawerFieldResponse.length;
  };

  // TODO: pull this function out into its own file...
  const setNewParam = (params: { [key: string]: FilterDrawerValue }[]) => {
    let fullQueryParams: { [x: string]: any; } = { ...query };
    params.forEach((param) => {
      fullQueryParams = { ...fullQueryParams, ...param };
    });
    Object.keys(fullQueryParams).forEach((k) => {
      const value = fullQueryParams[k];
      if (!value) {
        delete fullQueryParams[k];
      }
    });
    setQuery({ ...fullQueryParams }, 'replace');
  };

  useEffect(() => {
    if (initialLoaded.current) {
      localStorage.removeItem('subClipSegments');
      setTableCount(0);
      setTableData([]);
      dispatch(setLoadingSpinner(true));
      dispatch(translateQueryParamsToFilters({ queryParams: { ...query }, sortedFilterData: filterData }));
      const searchParams = location.search
        ? location.search
        : sessionStorage.assetsSearchParams;

      axios
        .get(`${API_ENDPOINTS.ASSETS}${searchParams}`)
        .then((resp) => {
          if (resp.data.dslSearch.error) {
            catchError(resp.data.dslSearch.error);
          }
          // Set up filters based on any url params
          setTableData(formatTableData(resp.data.dslSearch.data));
          setTableCount(resp.data.dslSearch.total);
        })
        .catch(catchError).finally(() => {
          dispatch(setLoadingSpinner(false));
        });
    }
  }, [query]);

  useEffect(() => {
    dispatch(setLoadingSpinner(true));
    if (!user || !networks) return;
    if (!initialLoaded.current) {
      dispatch(setFilterToEdit(null));

      axios.get(`${API_ENDPOINTS.ASSETS}${location.search || ''}`)
        .then((res) => {
        /**
         * @note if dslSearch throws an error the data key will be set to [] and total will be set to 0
         */
          if (res.data.dslSearch.error) {
            catchError(res.data.dslSearch.error);
          }

          /**
         * Optimistically Update table data with saved asset data then clear the saved data cache.
         * The form is waiting 3 seconds before opening the asset dashboard this should be enough
         * time of the micro services to update... If not use the optimisticallyUpdateData function
         * to update values optimistically...
         */
          const updatedData = optimisticallyUpdateData(savedAssets, res.data.dslSearch.data);
          setTableData(formatTableData(updatedData));
          setTableCount(res.data.dslSearch.total);
          // Clear savedAssets cache when user refreshes page
          dispatch(setSavedAssets([]));
        }).finally(() => {
          dispatch(setLoadingSpinner(false));
        });
      // Fetch Asset data
      Promise.all([
        axios.get(`${API_ENDPOINTS.ASSETS}/fields/search`),
        axios.get(`${API_ENDPOINTS.SAVED_SEARCHES}?context=${context}`),
      ]).then((resp) => {
        const sortedColumnsByOrder = ([...DEFAULT_ASSET_COLUMNS])
          .sort((a: ConfigureItem, b: ConfigureItem) => (!a.order || !b.order ? -1 : a.order - b.order));
        const columnsNum = ALL_ASSET_COLUMNS.length;
        const columns = { ...DrawerSortingService.sortData(ALL_ASSET_COLUMNS), total: columnsNum };
        const customColumns = localStorage.assetColumns
          ? JSON.parse(localStorage.assetColumns)
          : sortedColumnsByOrder;

        const designatedBrands = useBrandsFilter({ allBrands: networks, user });
        const assetFilterFields = resp[0].data.assetFilterFields;
        assetFilterFields.az.B[0].values = formatSupplyOptions(designatedBrands);
        assetFilterFields.category.USERS[0].values = formatSupplyOptions(designatedBrands);
        setFilterData(assetFilterFields);

        const filterObjects = [];
        const assetFieldLookup = resp[0].data.assetFieldData;
        assetFieldLookup[2].values = formatSupplyOptions(designatedBrands);

        // Set filters based on url params not sessionStorage
        const filterSet = new Set();
        const filterParams = new URLSearchParams(location.search);
        // eslint-disable-next-line no-restricted-syntax
        for (const key of filterParams.keys()) {
          filterSet.add(key);
        }
        // eslint-disable-next-line no-restricted-syntax
        for (const key of filterSet.keys()) {
          const found = assetFieldLookup.find((field: any) => field.name.startsWith(key));
          if (found?.type === 'LOOKUP') {
            found.value = found.values.filter((v: any) => filterParams.getAll(key).includes(v.code));
            filterObjects.push(found);
          } else if (found) {
            found.value = filterParams.get(key);
            filterObjects.push(found);
          }
        }

        const newQueryArray: any[] = [];
        filterObjects.forEach((filter) => {
          newQueryArray.push(createQueryObj(filter, filter.value));
        });
        setNewParam(newQueryArray);

        dispatch(updateSelectedFilters(filterObjects));
        dispatch(updateFilterObject(filterObjects));

        // update column data
        dispatch(updateSelectedColumns(customColumns));
        setDefaultColumns(sortedColumnsByOrder);
        filterSortedColumns(columns);
        setColumnsNumber(columnsNum);
        setSortedColumns(columns);
        if (resp[1] && Array.isArray(resp[1].data)) {
          dispatch(setSavedFilters(resp[1].data));
        }
        dispatch(translateQueryParamsToFilters({ queryParams: query, sortedFilterData: assetFilterFields }));
        initialLoaded.current = true;
      }).catch(catchError);
    }
    return () => {
      // clear properties drawer data
      // TODO: clear all column data
      dispatch(setPropertiesDrawerType(false));
      dispatch(setSelectedAsset(null));
    };
  }, [user, networks]);

  const fullTextSearch = (searchTerm: string) => {
    if (!searchTerm) {
      setQuery({ ...query, 'fullTextSearch-keywordContains': undefined, page: null });
    } else {
      setQuery({ ...query, 'fullTextSearch-keywordContains': searchTerm, page: null });
    }
  };

  const updateTablePageNumber = (page: number) => {
    setQuery({ ...query, page });
  };

  const updateTableSortingOrder = (colSortingParam: any) => {
    setQuery({ ...query, sort: colSortingParam });
  };

  const updateTablePageLimit = (limit: number) => {
    setQuery({ ...query, limit });
  };

  const toggleFilterDrawer = (open = !filterOpen, tab = TAB.FILTER) => {
    setFilterOpen(open);
    setFilterTab(tab);
  };

  const openSaveFilter = () => {
    toggleFilterDrawer(true, TAB.SAVED_FILTER);
  };

  const loadFilter = (filter: SavedFilter) => {
    dispatch(setFilterToEdit({ ...filter }));
    toggleFilterDrawer(false);

    const params = new URLSearchParams(filter.searchCriteria);
    const newQuery = Array.from(params.entries()).reduce((map: any, [key, value]) => {
      if (Object.prototype.hasOwnProperty.call(map, key)) {
        // if the current key is already an array, we'll add the value to it
        if (Array.isArray(map[key])) {
          map[key] = [...map[key], value];
        } else {
          // if it's not an array, but contains a value, we'll convert it into an array
          // and add the current value to it
          map[key] = [map[key], value];
        }
      } else {
        // plain assignment if no special case is present
        map[key] = value;
      }

      return map;
    }, {});
    setQuery(newQuery, 'replace');
  };

  const updateFilterObjects = (filter: SearchableField, code: string) => {
    let value;
    switch (filter.type) {
      case 'LOOKUP': {
        const filteredValues = (filter.value as LookupResponse[]).filter((val) => val.code !== code);
        value = filteredValues;
        break; }
      case 'TEXT':
      case 'KEYWORD':
      case 'LONG':
      case 'INTEGER':
      case 'BYTE':
      case 'DURATION':
        value = '';
        break;
      case 'BOOLEAN':
        value = false;
        break;
      case 'DATETIME':
        value = '';
        break;
      default:
        // do nothing
    }
    dispatch(setFilterObject({ ...filter, value }));
  };

  const toggleOffFilter = (filter: SearchableField, code: string) => {
    updateFilterObjects(filter, code);
    const newQuery = JSON.parse(JSON.stringify(query));
    // If it's a multi value one, splice it or make it undefined if it's gonna be empty
    if (Array.isArray(newQuery[filter.name])) {
      const idx = newQuery[filter.name].findIndex((item: string) => item === code);
      newQuery[filter.name].splice(idx, 1);
      if (!newQuery[filter.name].length) {
        newQuery[filter.name] = undefined;
      }
    } else if (EXACT_FILTERS.includes(filter.name)) {
      newQuery[`${filter.name}-exact`] = undefined;
    } else if (KEYWORD_CONTAINS_TYPES.includes(filter.type)) {
      // If it's a keyword contains one, need to add that to the param
      newQuery[`${filter.name}-keywordContains`] = undefined;
    } else {
      newQuery[filter.name] = undefined;
    }

    setQuery(newQuery);
  };

  const order = () => {
    const sourceSystems = selectedData.map((data) => data.sourceSystem);
    sourceSystems.forEach((sourceSystem) => {
      switch (sourceSystem) {
        case 'PMAM':
          navigate('order-assets', {
            state: {
              assetIds: selectedData.map((data) => data.id),
            },
          });
          break;
        case 'SUPPLY':
          navigate('create-orders', {
            state: {
              assetIds: selectedData.map((data) => data.id),
            },
          });
          break;
        default:
          // do nothing
      }
    });
  };

  const addToCollection = () => {
    const assetIds: any[] = selectedData.map((data) => {
      const id = featureFlags.sharedCollections ? data.materialId : data.pmam?.assetId;
      return id || data.id;
    });
    navigate('/collections/add', {
      state: {
        assetIds,
      },
    });
  };

  const navToEditAsset = () => {
    navigate('/assets/edit-assets', { state: { btnClicked: 'edit-asset' } });
  };

  const navToRegisterAsset = () => {
    navigate('/assets/edit-assets', { state: { btnClicked: 'register-asset' } });
  };

  const refreshTableDataWithUpdates = (rowsToUpdate: any) => {
    const newTableDate = tableData.map((row) => {
      let newRow = { ...row };
      const rowWithUpdate = rowsToUpdate.find((r: any) => r.id === newRow.pmam?.assetId);
      if (rowWithUpdate) {
        newRow = { ...newRow, ...rowWithUpdate.rowData };
      }
      return newRow;
    });
    setTableData(newTableDate);
  };

  const updateFilters = (f: SavedFilter[]) => {
    const filters = f.map((filter) => ({ ...filter }));
    filters.forEach((filter, index) => {
      filter.order = index;
    });
    dispatch(doUpdateSavedFilters(filters, context));
  };

  const removeFilter = (confirm: boolean) => {
    if (confirm && confirmRemoveFilter) {
      const filters = savedFilters.filter((f) => f.id !== confirmRemoveFilter.id);
      updateFilters(filters);
    }
    dispatch(setConfirmRemoveFilter(null));
  };

  return (
    <Box sx={{ ...classes.root }} className="landing" data-testid="assets-landing" ref={drawerPageRef}>
      {selectedAsset && (
        <PropertiesDrawer type="assets">
          <PropertiesTabs />
        </PropertiesDrawer>
      )}
      {filterData && (
        <FilterDrawer
          context={context}
          toggleDrawer={() => toggleFilterDrawer(false)}
          filterTab={filterTab}
          setFilterTab={setFilterTab}
          filterOpen={filterOpen}
          pageRef={drawerPageRef}
          loadFilter={loadFilter}
          updateSavedFilters={(filters) => dispatch(doUpdateSavedFilters(filters, context))}
          filterData={filterData}
          loadingData={loadingSpinner}
          setNewQuery={setNewParam}
        />
      )}
      {sortedColumns && defaultColumns && (
        <ConfigureColumnDrawer
          pageRef={drawerPageRef}
          defaultColumns={defaultColumns}
          sortedColumns={sortedColumns}
          columnsNumber={columnsNumber}
        />
      )}
      {confirmRemoveFilter?.context === context && (
        <ConfirmModal
          onClose={removeFilter}
          heading="Remove Filter"
          text="Are you sure you want to delete this saved filter?"
          action="Delete"
        />
      )}
      <Box
        className="table-container"
        sx={{
          ...(filterOpen && { '&.table-container': { marginLeft: '440px' } }),
        }}
      >
        <mui.Card>
          <mui.CardContent>
            <Box className="filter-search">
              <Box className="filter-icon">
                <FilterIcon
                  filterClicked={toggleFilterDrawer}
                  loadingData={loadingSpinner}
                />
              </Box>
              <Box className="filter-chips">
                <Box className="filter-chips-scrolling">
                  {assetsFilters?.map((filter: SearchableField) => {
                    if (Array.isArray(filter.value)) {
                      return (filter.value as LookupResponse[]).map((val: LookupResponse) => (
                        <NbcuChip
                          id={`${filter?.displayName?.toString().replace(/\s/g, '')}:${val?.displayName.replace(/\s/g, '')}`}
                          label={`${formatFieldNames(filter.displayName.toString())}:${val?.displayName}`}
                          key={`${filter?.displayName?.toString().replace(/\s/g, '')}:${val?.displayName.replace(/\s/g, '')}`}
                          onChipDeleted={() => toggleOffFilter(filter, val.code)}
                          clickable
                        />
                      ));
                    }
                    return (
                      <NbcuChip
                        id={`${filter.displayName.replace(/\s/g, '')}:${(filter.value as string|number).toString().replace(/\s/g, '')}`}
                        label={`${formatFieldNames(filter.displayName)}:${(filter.value as string|number)}`}
                        key={`${filter.displayName}:${(filter.value as string|number)}`}
                        onChipDeleted={() => toggleOffFilter(filter, filter.value as string)}
                        clickable
                      />
                    );
                  })}
                  {assetsFilters?.length > 0 && (
                  <mui.Button onClick={openSaveFilter} data-testid="save-filter-button">
                    Save Filter
                  </mui.Button>
                  )}
                </Box>
              </Box>
              <Box className="filter-search">
                <SearchInput
                  submitSearch={fullTextSearch}
                  searchTerm={query['fullTextSearch-keywordContains']}
                />
              </Box>
            </Box>
            <Box className={filterOpen ? 'table-filter table-filter-open' : 'table-filter'} data-testid="assets-table">
              {!loadingSpinner && tableData?.length === 0 && (
              <Box className="table-no-data">No data found for current search. Please adjust your filters or, refresh the page.</Box>
              )}
              <TableWrapper
                buttonActions={{
                  order,
                  'add to collection': addToCollection,
                  edit: navToEditAsset,
                  'register asset': navToRegisterAsset,
                }}
                tableData={tableData}
                tableCount={tableCount}
                tablePage={query.page || 1}
                tableLimit={query.limit || 100}
                type="assets"
                updateTableSortingOrder={updateTableSortingOrder}
                updateTablePageNumber={updateTablePageNumber}
                updateTablePageLimit={updateTablePageLimit}
                updateSelectedData={setSelectedData}
                updateTableData={refreshTableDataWithUpdates}
              />
            </Box>
          </mui.CardContent>
        </mui.Card>
      </Box>
    </Box>
  );
}
