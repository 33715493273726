import React, { Suspense } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import AssetsLanding from '../../../assets/components/AssetsLanding';
import { assetsModule } from '../../../assets';
import { collectionsModule } from '../../../collections';
import { loginModule } from '../../../login';
import { playlistsModule } from '../../../playlists';
import { workOrdersModule } from '../../../work-orders';
import { studioSupplyOrdersModule } from '../../../studio-supply-orders';
import FeatureFlagsLanding from '../../../feature-flags';

import AppUser from './appData/AppUser';
import AppOptions from './appData/AppOptions';
import LoadingSpinner from './LoadingSpinner';

export default function AppRouter() {
  return (
    <Suspense fallback={<LoadingSpinner size="100px" />}>
      <AppUser />
      <AppOptions />
      <Routes>
        <Route path="/" element={<Navigate to={`/assets${sessionStorage.assetsSearchParams || ''}`} />} />
        <Route path="/assets" element={<AssetsLanding />} />
        <Route path="/feature-flags" element={<FeatureFlagsLanding />} />
        {loginModule.routes}
        {assetsModule.routes}
        {collectionsModule.routes}
        {playlistsModule.routes}
        {workOrdersModule.routes}
        {studioSupplyOrdersModule.routes}
      </Routes>
    </Suspense>
  );
}
