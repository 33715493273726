import React from 'react';
import { Box, mui } from 'tl-storybook';
import { ICellRendererParams } from 'ag-grid-community/dist/lib/rendering/cellRenderers/iCellRenderer';
import { classes } from './MediatorStatusRenderer.styles';

export default function MediatorStatusRenderer(props: Partial<ICellRendererParams>) {
  const { data, colDef } = props;
  const { mediatorProgressPercentage } = data;

  const circleProps = { value: mediatorProgressPercentage, size: '35px', thickness: 1.5 };
  return (
    <Box
      data-testid="mediator-progress-renderer"
      sx={{
        ...classes.progressWrapper,
        ...(colDef?.rowDensity === 'comfortable' ? { height: '100px' } : { height: '52px' }),
      }}
    >
      <mui.CircularProgress variant="determinate" {...circleProps} />
      <Box
        component="span"
        sx={{ ...classes.progressText }}
      >
        {`${Math.round(mediatorProgressPercentage)}%`}
      </Box>
    </Box>
  );
}
