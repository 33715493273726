import React from 'react';
import { icons } from 'tl-storybook';
import { TableButtonProps } from '../../interfaces/table-button';

const getCreateOrderErrorMsg = (user?: any, selectedOrders: any[] = []) => {
  const pmamStorageAssets = selectedOrders.filter((a: any) => a.storageSystem === 'PMAM_MEDIATOR');
  const notPmamStorageAssets = selectedOrders.filter((a: any) => a.storageSystem !== 'PMAM_MEDIATOR');
  const mixedStorageSelected = pmamStorageAssets.length && notPmamStorageAssets.length;
  const missingProductionNumber = notPmamStorageAssets.filter((a: any) => !a.productionNumber);
  const userHasNoSupplyPolicies = notPmamStorageAssets.length
  && !user?.policies?.supplyOrders?.create?.length;
  const restrictedAssets = selectedOrders.filter((o) => o.pmam?.restrictedStatus === 'RESTRICTED');
  if (mixedStorageSelected) {
    return 'Unable to create an order when PMAM_MEDIATOR and non PMAM_MEDIATOR assets selected';
  }
  if (missingProductionNumber.length) {
    const materialIds = missingProductionNumber.map((a:any) => a.materialId);
    return `
      There is no production number for
      asset${materialIds.length === 1 ? '' : 's'} ${materialIds.join(', ')}.
      Cannot create work order. Please contact support.
    `;
  }

  if (restrictedAssets.length) {
    const materialIds = restrictedAssets.map((a:any) => a.materialId);
    return `
    asset${materialIds.length === 1 ? '' : 's'} ${materialIds.join(', ')}
    ${materialIds.length === 1 ? 'has' : 'have'} restrictions.  Ordering asset is unavailable
    `;
  }
  if (userHasNoSupplyPolicies) {
    return 'Not authorized to create a supply order';
  }
  return '';
};

const getEditOrderErrorMsg = (user?: any, selectedOrders: any[] = []) => {
  const supplyAsset = selectedOrders.some((a: any) => a.sourceSystem?.toLowerCase() === 'supply');
  const restrictedAssets = selectedOrders.filter((o) => o.pmam?.restrictedStatus === 'RESTRICTED');
  if (selectedOrders.every((a: any) => !!a?.pmam?.markedForDelete)) {
    return 'Assets marked for delete can\'t be edited';
  }

  if (restrictedAssets.length) {
    const materialIds = restrictedAssets.map((a:any) => a.materialId);
    return `
    asset${materialIds.length === 1 ? '' : 's'} ${materialIds.join(', ')}
    ${materialIds.length === 1 ? 'has' : 'have'} restrictions.  Editing asset is unavailable
    `;
  }

  if (supplyAsset) {
    return 'Supply assets meta data can\'t be edited';
  }

  return '';
};

// All functions are placeholders
export const COLLECTION_ASSETS_BUTTONS: TableButtonProps[] = [
  {
    text: 'order',
    icon: <icons.ShoppingCart />,
    isDisabledFunction: (user?: any, selectedOrders: any[] = []) => {
      const hasErrorMsg = getCreateOrderErrorMsg(user, selectedOrders);
      return selectedOrders.length === 0 || Boolean(hasErrorMsg);
    },
    getTooltipTitle: (user?: any, selectedOrders: any[] = []) => getCreateOrderErrorMsg(user, selectedOrders),
  },
  {
    text: 'edit',
    icon: <icons.Edit />,
    isDisabledFunction: (user?: any, selectedOrders: any[] = []) => {
      const hasErrorMsg = getEditOrderErrorMsg(user, selectedOrders);
      return selectedOrders.length === 0 || Boolean(hasErrorMsg);
    },
    getTooltipTitle: (user, selectedOrders = []) => getEditOrderErrorMsg(user, selectedOrders),
  },
  {
    text: 'remove from collection',
    icon: <icons.Queue />,
    isDisabledFunction: (user?: any, selectedOrders: any[] = []) => selectedOrders.length === 0,
    getTooltipTitle: () => '',
  },
  {
    text: 'register asset',
    icon: <icons.Backup />,
    isDisabledFunction: () => false,
    getTooltipTitle: () => '',
  },
];
