import React, { useState } from 'react';
import {
  Box,
  Grid,
  mui,
  icons,
  NbcuSnackbar,
} from 'tl-storybook';
import { LogEntry } from '../../../../interfaces/log';
import { useAppSelector } from '../../../../interfaces/hooks';

import { classes } from './LogTab.styles';

interface Props {
  data: LogEntry[];
  createComment: (comment: string) => void;
}

const LOG_BUTTONS = ['All Activity', 'Comments Only'];

const SOURCES: { [key: string]: string } = {
  MAM: 'TL',
  MEDIATOR: 'M',
  PROM: 'P',
};

function TextStepperIcon(item: LogEntry) {
  const { type, source } = item;
  switch (type) {
    case 'COMMENT':
      return <icons.Comment />;
    case 'CANCEL':
      return <icons.ErrorOutline className="error" />;
    case 'CREATE':
      return <icons.Add />;
    case 'RETRY':
      return <icons.Refresh />;
    default:
      return <div className="text-stepper-icon">{SOURCES[source]}</div>;
  }
}

export default function LogTab(props: Props) {
  const { data, createComment } = props;

  const { assetLogsLoading } = useAppSelector((state:any) => state.propertiesDrawer);

  const [activeButton, setActiveButton] = useState<string>('All Activity');
  const [comment, setComment] = useState<string>('');
  const handleSelection = (selection: string) => {
    setActiveButton(selection);
  };

  function getStepLabel(item: LogEntry) {
    return (
      <mui.StepLabel data-testid={`step-label-${item.title}`} StepIconComponent={() => TextStepperIcon(item)}>
        {item.title}
      </mui.StepLabel>
    );
  }

  const addComment = (event: any) => {
    if (event.keyCode === 13) {
      createComment(comment);
      setComment('');
    }
  };

  const handleCommentChange = (event: any) => {
    setComment(event.target.value);
  };

  return (
    <Box sx={{ height: '100%' }}>
      {assetLogsLoading && (
        <Box className="loading-overlay" data-testid="filter-drawer-loading-spinner">
          <mui.CircularProgress />
        </Box>
      )}
      <Box sx={{ ...classes.root }} data-testid="assets-properties-panel-log-tab">
        <mui.ButtonGroup>
          {LOG_BUTTONS.map((button) => (
            <mui.Button
              key={`media-type-filter-${button}`}
              data-testid={`media-type-filter-${button}`}
              onClick={() => handleSelection(button)}
              className={activeButton === button ? 'active' : ''}
            >
              {button}
            </mui.Button>
          ))}
        </mui.ButtonGroup>
        <Box className="comment-add">
          <mui.TextField
            variant="outlined"
            placeholder="Add A Comment"
            data-testid="assets-properties-panel-log-tab-add-comment-input"
            onChange={handleCommentChange}
            onKeyDown={addComment}
            disabled
            sx={{
              '.MuiOutlinedInput-input': {
                padding: '18.5px 14px',
              },
            }}
          />
        </Box>
        <Box className="log-data" data-testid="log-tab-comments">
          <mui.Stepper
            activeStep={0}
            orientation="vertical"
            sx={{
              '&.MuiStepper-root': {
                padding: '24px',
              },
            }}
          >
            {data.map !== undefined && data.map((item, index) => (
              (activeButton === 'All Activity' || item.type === 'COMMENT') && (
              <mui.Step key={`log-item-${item.id}`} completed active data-testid={`log-item-${index}`}>
                {getStepLabel(item)}
                <mui.StepContent>
                  <Box className="log-desc" data-testid={`log-tab-desc-${item.title}`}>
                    {item.description}
                  </Box>
                  <Box className="created-info">
                    <Grid container>
                      <Grid item xs={6} data-testid={`log-tab-created-at-${item.title}`}>
                        {item.createdAt}
                      </Grid>
                      <Grid className="text-right" item xs={6} data-testid={`log-tab-created-by-${item.title}`}>
                        {item.createdBy}
                      </Grid>
                    </Grid>
                  </Box>
                </mui.StepContent>
              </mui.Step>
              )
            ))}
          </mui.Stepper>
        </Box>
      </Box>

      <NbcuSnackbar
        id="logTab_error"
        message={data.message}
        open={Boolean(data.message)}
      />
    </Box>
  );
}
