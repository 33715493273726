import React from 'react';
import { Box, icons } from 'tl-storybook';
import { ICellRendererParams } from 'ag-grid-community/dist/lib/rendering/cellRenderers/iCellRenderer';
import { classes } from './ApprovalStatusRenderer.styles';

export default function ApprovalStatusRenderer(props: Partial<ICellRendererParams>) {
  const { data, colDef } = props;

  const approvalStatus: string = data.pmam?.svApprovalStatus || 'NONE';

  const getIcon = (status: string) => {
    if (status === 'APPROVED') {
      return <><icons.Check sx={{ ...classes.statusIcon }} color="success" aria-label="Approved" /><span>Approved</span></>;
    }
    if (status === 'NOT_REQUIRED') {
      return <span>Not Required</span>;
    }
    if (status === 'PENDING') {
      return <><icons.HourglassBottom sx={{ ...classes.statusIcon }} color="primary" aria-label="Pending" /><span>Pending</span></>;
    }
    if (status === 'REJECTED') {
      return <><icons.Cancel sx={{ ...classes.statusIcon }} color="error" aria-label="Rejected" /><span>Rejected</span></>;
    }
    if (status === 'NONE') {
      return null;
    }
  };

  return (
    <Box
      data-testid="approval-status-renderer"
      sx={{
        ...classes.statusWrapper,
        ...(colDef?.rowDensity === 'comfortable' ? { height: '100px' } : { height: '52px' }),
      }}
    >
      {getIcon(approvalStatus)}
    </Box>
  );
}
