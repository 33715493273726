import React from 'react';
import { Box } from 'tl-storybook';

const ErrorMediator8 = () => (
  <Box>
    <Box><h4>Error: A network error caused the media download to fail part-way.</h4></Box>
    <Box><h4>Cause: The server may have disconnected from the player.</h4></Box><br />
    <Box>
      Mediator 8 assets are still on an old &quot;on-prem&quot; server and does not currently handle lots of traffic.
      This is a known issue and currently being worked on.
    </Box><br />
    <Box>
      When accessing Mediator 8 assets try and handle the video with care.
      <ul>
        <li>
          When the video is buffering allow the video to load and play before giving the video another command.
        </li>
        <li>Try not to drag quickly on the timeline.</li>
        <li>After changing audio tracks wait for a few seconds before giving the video another command.</li>
      </ul>
    </Box>
  </Box>
);

export default ErrorMediator8;
