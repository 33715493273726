import { Box } from 'tl-storybook';
import { ICellRendererParams } from 'ag-grid-community/dist/lib/rendering/cellRenderers/iCellRenderer';
import { formatMasterSubmaster } from '../../../helpers';

import { classes } from './MasterTypeRenderer.styles';

const MasterTypeRenderer = (props: Partial<ICellRendererParams>) => {
  const { data, colDef } = props;
  return (
    <Box
      sx={{
        ...classes.textWrapper,
        ...(colDef?.rowDensity === 'comfortable' ? { height: '100px' } : { height: '52px' }),
      }}
    >
      { formatMasterSubmaster(data?.pmam?.masterType) }
    </Box>

  );
};

export default MasterTypeRenderer;
