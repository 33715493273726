import * as React from 'react';
import { mui } from 'tl-storybook';
import { useLocation } from 'react-router-dom';
import ErrorMediator8 from './ErrorMediator8';
import ConfirmationDialogRaw from './ConfirmationDialogRaw';
import { useAppDispatch, useAppSelector } from '../../../../interfaces/hooks';
import { setMediator8Error } from '../../../slices/UserFeedBackSlice';
import { setVideoJsOptions, setVideoMetaData } from '../../../slices/ProxySlice';
import { updatePreviewOpen, setWatermarkedUrl } from '../../../slices/PreviewSlice';
import { updateItems } from '../../../slices/SelectedSlice';

function DialogMediator8Error() {
  const dispatch: any = useAppDispatch();
  const location = useLocation();

  const mediator8Error = useAppSelector((state) => state.userFeedBack.mediator8Error);
  const { items } = useAppSelector((state) => state.selected);

  // TODO: Add a cancel button to the dialog.
  // const handleCancel = () => {
  //   dispatch(setMediator8Error({ open: false }));
  // };

  const handleConfirm = () => {
    // Close the video player.
    dispatch(setVideoJsOptions(null));
    dispatch(setVideoMetaData(null));
    dispatch(updatePreviewOpen(false));
    dispatch(setWatermarkedUrl(null));

    // reset the selected items to the original state
    if (location.pathname.includes('subclip')) {
      setTimeout(() => {
        dispatch(updateItems([{ ...items[0] }]));
      }, 10000);
    }

    // Reset the Dialog.
    dispatch(setMediator8Error({ open: false, title: '', confirmLabel: '' }));
  };

  return (
    <mui.Box>
      {mediator8Error.open && (
      <ConfirmationDialogRaw
        keepMounted
        open={mediator8Error.open}
        title={mediator8Error.title}
        confirmLabel={mediator8Error.confirmLabel}
        onConfirm={handleConfirm}
      >
        <ErrorMediator8 />
      </ConfirmationDialogRaw>
      )}
    </mui.Box>
  );
}

export default DialogMediator8Error;
