import { OptionWithCode } from '../interfaces/pmam-asset';

interface BrandsFilterProps {
    allBrands: any[],
    user: any
}

export function useBrandsFilter({ allBrands, user } : BrandsFilterProps): OptionWithCode[] {
  const designatedBrands = allBrands.filter((brand: any) => user.brandCode.includes(brand.code.toLowerCase()));
  return designatedBrands;
}
