import React from 'react';
import { Box, mui } from 'tl-storybook';
import Modal from '../Modal';
import { colors } from '../../../../../styles/base/colors';

interface ConfirmModalProps {
    heading: string;
    text: string;
    action: string;
    onClose: (confirm: boolean) => void,
}

export default function ConfirmModal(props: ConfirmModalProps) {
  const {
    heading, text, action, onClose,
  } = props;

  const confirmModalBody = (
    <>
      <p>{text}</p>
      <Box className="button-container">
        <mui.Button
          onClick={() => onClose(false)}
          className="cancel-button"
          data-testid="confirm-cancel-button"
          sx={{
            '&.MuiButton-textPrimary': {
              color: colors.black,
            },
          }}
        >
          Cancel
        </mui.Button>
        <mui.Button
          onClick={() => onClose(true)}
          className="confirm-button"
          data-testid="confirm-confirm-button"
          sx={{
            '&.MuiButton-textPrimary': {
              color: colors.black,
            },
          }}
        >
          {action}
        </mui.Button>
      </Box>
    </>
  );

  return (
    <Modal
      onClose={() => onClose(false)}
      heading={heading}
      bodyContent={confirmModalBody}
    />
  );
}
