import React, { useEffect, useState } from 'react';
import { Box, mui, icons } from 'tl-storybook';
import { Link } from 'react-router-dom';
import DrawerTitle from '../app/DrawerTitle';
import { setPropertiesDrawerType, getPmamAssetById, setPmamAssetLoading } from '../../slices/PropertiesDrawerSlice';
import { useAppSelector, useAppDispatch } from '../../../interfaces/hooks';

import { classes } from './PropertiesDrawer.styles';

const MEDIA_TYPE_ICONS: { [key: string]: React.ReactElement } = {
  VIDEO: <icons.LiveTv />,
  AUDIO: <icons.Headset />,
  IMAGE: <icons.Image />,
  DOCUMENT: <icons.Description />,
  PROJECT: <icons.FolderOpen />,
};

interface PropertiesDrawerProps {
  children: React.ReactFragment,
  type: string,
}

export default function PropertiesDrawer(props:PropertiesDrawerProps) {
  const { children, type } = props;
  const dispatch = useAppDispatch();
  const {
    propertiesDrawerOpen, selectedAsset, selectedWorkOrder, selectedSupplyOrder,
  } = useAppSelector((state) => state.propertiesDrawer);
  const [isSupply, setIsSupply] = useState<boolean>(false);
  const [isWorkOrder, setIsWorkOrder] = useState(false);
  const [fileTitle, setFileTitle] = useState<string>('');
  const [fileName, setFileName] = useState(null);

  useEffect(() => {
    switch (type) {
      case 'assets':
        setFileTitle(selectedAsset?.pmam?.fileTitle);
        setFileName(<div className="roboto-font">{selectedAsset?.pmam?.fileName}</div>);
        if (selectedAsset.pmam) {
          dispatch(setPmamAssetLoading(true));
          dispatch(getPmamAssetById(selectedAsset.pmam?.assetId)).finally(() => {
            dispatch(setPmamAssetLoading(false));
          });
        }
        break;
      case 'workOrders':
        setIsWorkOrder(true);
        setFileTitle(selectedWorkOrder.asset.fileName);
        setFileName(selectedWorkOrder.asset.fileTitle);
        break;
      case 'studioSupplyOrders':
        setIsSupply(true);
        setFileTitle(selectedSupplyOrder?.title);
        setFileName(null);
        break;
      default:
        // do nothing
    }
  }, [selectedAsset, selectedWorkOrder, selectedSupplyOrder]);

  /**
   * @note Clear properties panel data
   */
  const close = () => {
    dispatch(setPropertiesDrawerType(null));
  };

  return (
    <mui.Drawer
      sx={{ ...classes.root }}
      anchor="right"
      variant="persistent"
      open={propertiesDrawerOpen}
      PaperProps={{
        style: {
          width: '580px',
          boxShadow: '0 4px 5px 0 rgba(0,0,0,0.14), 0 1px 10px 0 rgba(0,0,0,0.12), 0 2px 4px -1px rgba(0,0,0,0.2)',
        },
      }}
      BackdropProps={{ invisible: true }}
      onClose={close}
    >
      <DrawerTitle
        title="Properties"
        toggleDrawer={close}
        data-testid="properties-tab-title"
      />
      {!isWorkOrder && (
        <Box className="top-chips">
          {
            // TODO whichever row they click, store that info in the context and map it here
          }
          <mui.Chip
            className="top-chip icon-chip"
            icon={MEDIA_TYPE_ICONS[selectedAsset?.mediaType?.toUpperCase() || 'VIDEO']}
            data-testid="properties-panel-media-icon-chip"
          />
          <mui.Chip className="top-chip" label={isSupply ? 'Supply' : 'PMAM'} data-testid="properties-panel-pmam-chip" />
          {!isSupply && selectedAsset?.pmam?.markedForDelete && (
            <mui.Chip
              className="top-chip red-chip"
              label="Marked for Delete"
              data-testid="properties-panel-marked-for-delete-chip"
            />
          )}
        </Box>
      )}
      <Box className="asset-title">
        <mui.Grid container>
          <mui.Grid item xs={8}>
            <Box className="text-bold roboto-font asset-id" data-testid="properties-panel-asset-id-label" style={isWorkOrder ? { fontSize: '20px' } : {}}>
              #
              {selectedAsset?.id || selectedWorkOrder?.workorderId}
            </Box>
          </mui.Grid>
          {!isWorkOrder && (
            <mui.Grid item xs={4}>
              <Box className="text-right">
                <Link
                  to={`/assets/detail/${selectedAsset?.id?.toString()}`}
                  onClick={() => dispatch(setPropertiesDrawerType(null))}
                >
                  <mui.Button
                    variant="outlined"
                    data-testid="properties-tab-details-button"
                    title="Asset Details"
                  >
                    <icons.ArtTrack />
                  </mui.Button>
                </Link>
                <mui.Button variant="outlined" data-testid="properties-tab-edit-button" disabled title="Edit">
                  <icons.Edit />
                </mui.Button>
              </Box>
            </mui.Grid>
          )}
        </mui.Grid>
        {!isWorkOrder && <h2>{fileTitle}</h2>}
        {isWorkOrder && fileTitle}
        {fileName}
      </Box>
      {children}
    </mui.Drawer>
  );
}
