import React, { createContext } from 'react';
import { Box } from 'tl-storybook';
import { classes } from './DrawerSection.style';

const DrawerSectionContext = createContext({});

export default function DrawerSection({ children }: { children: React.ReactFragment }) {
  return (
    <DrawerSectionContext.Provider value="DrawerSection">
      <Box sx={{ ...classes.root }} data-testid="drawer-section">
        {children}
      </Box>
    </DrawerSectionContext.Provider>
  );
}
