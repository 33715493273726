export const optimisticallyUpdateData = (savedData: any, tableData: any) => {
  if (savedData.length === 0) return tableData;

  savedData.forEach((data: any) => {
    const found = tableData.find((d: any) => d.id === data.assetId);
    // Asset may no longer be in the results based on sort or search criteria
    if (!found) return;
    // Update found asset with saved data.
    const aspectRatio = data.pmamAsset?.aspectRatio ? data.pmamAsset.aspectRatio.description.split(':') : null;
    found.aspectRatio = aspectRatio && `AR_${aspectRatio[0]}_TO_${aspectRatio[1]}`;
    found.gtmId = data.pmamAsset.gtmId;
    found.gtmMdvUnifiedId = data.pmamAsset.gtmMdvUnifiedId;
    found.gtmVersionUnifiedId = data.pmamAsset.gtmVersionUnifiedId;
    found.materialType = data.pmamAsset.materialType?.description || null;
    found.productionType = data.pmamAsset.productionType?.description || null;
    found.pmam.airtableId = data.pmamAsset.airtable?.airtableId || null;
    found.pmam.copyrightOwner = data.pmamAsset.copyrightOwner;
    found.pmam.language = data.pmamAsset.language?.description || null;
    found.pmam.merlinDeliveryStatus = data.pmamAsset.merlinDeliveryStatus;
    found.pmam.restrictedStatus = data.pmamAsset.restrictedStatus;
    found.pmam.svApprovalStatus = data.pmamAsset.svApprovalStatus;
    found.pmam.versionType = data.pmamAsset.versionType?.description || null;
    found.tmsId = data.pmamAsset.tmsId;
    found.pmam.fileTitle = data.pmamAsset.fileTitle;
    found.seasonNo = data.pmamAsset.seasonNo;
    found.seriesTitle = data.pmamAsset.seriesTitle;
    found.seasonTitle = data.pmamAsset.gtm?.seasonTitle;
    found.episodeTitle = data.pmamAsset.gtm?.episodeTitle;
    found.episodeNo = data.pmamAsset.gtm?.episodeNo;
    found.featureTitle = data.pmamAsset.gtm?.featureTitle;
    found.brand = data.pmamAsset.userNetwork?.description;
    found.textVersion = data.pmamAsset.textType?.code;
  });

  return tableData;
};
