import axios from 'axios';
import React, { useEffect, useState } from 'react';
import {
  Box, mui, NbcuTextField, icons,
} from 'tl-storybook';
import moment from 'moment';
import { useLocation } from 'react-router-dom';
import debounce from 'lodash.debounce';
import { LookupResponse, SearchableField } from 'src/app/interfaces/searchable-field';
import { FilterDrawerValue } from '../../../../interfaces/drawer-item';
import { FILTER_ITEM_TYPES } from '../../../consts/FILTER_ITEM_TYPES';
import { formatFieldNames } from '../../../utils';
import { useAppSelector, useAppDispatch } from '../../../../interfaces/hooks';
import { API_ENDPOINTS } from '../../../consts/API_ENDPOINTS';
import { colors } from '../../../../../styles/base/colors';
import { classes } from './FilterInput.styles';
import { setFilterObject } from '../../../slices/FilterSlice';

interface Props {
  field: SearchableField;
  filterName: string;
  setApplyButtonDisabled: (x: boolean) => void;
}

enum INPUT_TYPE {
  LOOKUP,
  BOOLEAN,
  DATETIME,
  TEXT
}

export default function FilterInput(props: Props) {
  const {
    field, filterName, setApplyButtonDisabled,
  } = props;

  const workOrdersFilters = useAppSelector((state) => state.filter.workOrdersFilters);
  const assetsFilters = useAppSelector((state) => state.filter.assetsFilters);
  const studioSupplyOrdersFilters = useAppSelector((state) => state.filter.studioSupplyOrdersFilters);

  const [value, setValue] = useState<FilterDrawerValue>('');
  const [lookupFields, setLookupFields] = useState<any[]>([]);
  const [inputType, setInputType] = useState<INPUT_TYPE>(null);
  const [titleSuggestions, setTitleSuggestions] = useState<any[]>([]);
  const [titleInputValue, setTitleInputValue] = useState<string>('');
  const location = useLocation();
  const dispatch = useAppDispatch();

  const getInputType = (searchableField: SearchableField) => {
    switch (searchableField.type) {
      case FILTER_ITEM_TYPES.KEYWORD:
      case FILTER_ITEM_TYPES.LONG:
      case FILTER_ITEM_TYPES.INTEGER:
      case FILTER_ITEM_TYPES.BYTE:
      case FILTER_ITEM_TYPES.DURATION:
        return INPUT_TYPE.TEXT;
      case FILTER_ITEM_TYPES.LOOKUP:
        setLookupFields(searchableField.values);
        return INPUT_TYPE.LOOKUP;
      case FILTER_ITEM_TYPES.BOOLEAN:
        return INPUT_TYPE.BOOLEAN;
      case FILTER_ITEM_TYPES.DATETIME:
        return INPUT_TYPE.DATETIME;
      default:
        return null;
    }
  };

  const getFilterValue = (filter: SearchableField): FilterDrawerValue => {
    const filterValue = filter?.value;
    switch (field.type) {
      case FILTER_ITEM_TYPES.LOOKUP:
        return filterValue || [];
      case FILTER_ITEM_TYPES.BOOLEAN:
        return filterValue || false;
      case FILTER_ITEM_TYPES.DATETIME:
        // datetime needs to be date for TextField, time is appended back in handleDateChange
        return filterValue ? moment.utc(filterValue.toString()).format('YYYY-MM-DD') : '';
      default:
        return filterValue || '';
    }
  };

  useEffect(() => {
    let selectedFilters = [];
    // TODO: add cases for studio supply orders and collections
    switch (location.pathname) {
      case '/work-orders':
        selectedFilters = workOrdersFilters;
        break;
      case '/studio-supply-orders':
        selectedFilters = studioSupplyOrdersFilters;
        break;
      default:
        selectedFilters = assetsFilters;
    }
    const filter = selectedFilters.find((f) => f.name.replace('-code', '') === filterName);
    setValue(getFilterValue(filter));
  }, [assetsFilters, workOrdersFilters, studioSupplyOrdersFilters, filterName]);

  useEffect(() => {
    setInputType(getInputType(field));
  }, [field]);

  const hasInputError = (searchableField: SearchableField, filterDrawerValue: FilterDrawerValue): boolean => {
    if (filterDrawerValue) {
      if (searchableField.name === 'duration' || searchableField.name === 'totalRunTime') {
        return !(/^\d{2}:\d{2}:\d{2}:\d{2}$/.test(filterDrawerValue.toString()));
      }
    }

    return false;
  };

  const handleEnter = () => {
    // TODO: handle enter event
    // if (!hasInputError(field, value)) {
    //   if (event.key === 'Enter') {
    //     applyFilters();
    //   }
    // }
  };

  const handleChange = (event: any) => {
    setValue(event.target.value);
    dispatch(setFilterObject({ ...field, value: event.target.value, name: filterName }));
    setApplyButtonDisabled(false);
    // TODO: handle enter event
    // if (event.keyCode === 13) {
    //   applyFilters();
    // }
  };

  const onTitleChange = (e: any, newValue: string) => {
    setValue(newValue);
    dispatch(setFilterObject({ ...field, value: newValue, name: filterName }));
    setApplyButtonDisabled(false);
    // TODO: handle enter event
    // if (e.key === 'Enter' && titleSuggestions.length) {
    //   setTitleSuggestions([]);
    //   applyFilters();
    // }
  };

  const debouncedGetTitleSuggestions = React.useRef(debounce((newInputValue: string, fieldName: string) => {
    let endpoint;
    switch (fieldName) {
      case 'featureTitle':
        endpoint = API_ENDPOINTS.SUGGEST_FEATURE_TITLES;
        break;
      case 'seriesTitle':
        endpoint = API_ENDPOINTS.SUGGEST_SERIES_TITLES;
        break;
      default:
          // do nothing;
    }
    axios
      .get(`${endpoint}?title=${newInputValue}`)
      .then((suggestions: any) => {
        setTitleSuggestions(suggestions.data);
      }).catch((err) => {
        console.log(err);
      });
  }, 1000)).current;

  const onTitleInputChange = (e: any, newInputValue: string, fieldName: string) => {
    setTitleInputValue(newInputValue);
    debouncedGetTitleSuggestions(newInputValue, fieldName);
  };

  const handleBooleanChange = (event: any) => {
    const val = event.target.checked;
    const newValue = typeof value === 'string' ? val.toString() : val;

    setValue(newValue);
    dispatch(setFilterObject({ ...field, value: newValue, name: filterName }));
    setApplyButtonDisabled(false);
  };

  const handleLookupChange = (val: LookupResponse) => {
    const newValue = [...(value as LookupResponse[])];
    const index = val.code
      ? newValue.findIndex((fieldVal) => fieldVal.code === val.code)
      : newValue.findIndex((fieldVal) => fieldVal.id === val.id);
    if (index < 0) {
      newValue.push(val);
    } else {
      newValue.splice(index, 1);
    }

    setValue(newValue);
    dispatch(setFilterObject({ ...field, value: newValue, name: filterName }));
    setApplyButtonDisabled(false);
  };

  const handleDateChange = (event: any) => {
    const val = event.target.value;
    setValue(val);
    let date = moment.utc(val, 'YYYY-MM-DD');
    if (date.isValid()) {
      date = filterName.endsWith('-after') || filterName.endsWith('-gt-iso') ? date.startOf('day') : date.endOf('day');
    }
    dispatch(setFilterObject({ ...field, value: date.toISOString(), name: filterName }));
    setApplyButtonDisabled(false);
  };

  const isLookupChecked = (val: any) => {
    const lookupValue = value as LookupResponse[];
    // because value has different types, make sure it's right by checking if it's an array
    if (val.code) {
      return Array.isArray(lookupValue) && lookupValue.some((v) => (v ? v.code === val.code : false));
    }
    if (val.id) {
      return Array.isArray(lookupValue) && lookupValue.some((v) => (v ? v.id === val.id : false));
    }
    return false;
  };

  const getErrorText = (searchableField: SearchableField): string => {
    let errorMsg = '';
    if (searchableField.name === 'duration' || searchableField.name === 'totalRunTime') {
      errorMsg = `Please enter ${searchableField.displayName} in HH:MM:SS:FF format`;
    }

    return errorMsg;
  };

  const getHelperText = (searchableField: SearchableField, filterDrawerValue: FilterDrawerValue) => (hasInputError(searchableField, filterDrawerValue) ? getErrorText(searchableField) : '');

  const getTextFieldType = (fieldName: any) => {
    const isAutocomplete = fieldName === 'featureTitle' || fieldName === 'seriesTitle';
    return isAutocomplete ? (
      <mui.Autocomplete
        freeSolo
        placeholder={formatFieldNames(field.displayName)}
        id={field.name}
        options={titleSuggestions.map((t: any) => t.title)}
        onChange={(e: any, newValue: string | null) => onTitleChange(e, newValue)}
        onInputChange={(e: any, newInputValue: string) => onTitleInputChange(e, newInputValue, field.name)}
        value={value}
        inputValue={titleInputValue}
        data-testid={`filter-input-${filterName}`}
        fullWidth
        sx={{
          '.MuiInput-root:after': {
            borderBottom: `2px solid ${colors.tealBlue}`,
          },
        }}
        renderInput={(params) => (
          <NbcuTextField
            {...params}
            label={field.displayName}
            debounce={3000}
            error={hasInputError(field, value)}
            helperText={getHelperText(field, value)}
          />
        )}
      />
    ) : (
      <mui.TextField
        placeholder={formatFieldNames(field.displayName)}
        onKeyDown={handleEnter}
        onChange={handleChange}
        value={value}
        data-testid={`filter-input-${filterName}`}
        error={hasInputError(field, value)}
        helperText={getHelperText(field, value)}
        variant="standard"
        fullWidth
        sx={{
          '.MuiInput-root:after': {
            borderBottom: `2px solid ${colors.tealBlue}`,
          },
        }}
      />
    );
  };

  const searchFields = (searchVal: string) => {
    const matchedFields = field.values.filter((val: any) => {
      const readableValue = formatFieldNames(val.displayName).toLowerCase();
      return readableValue.includes(searchVal.toLowerCase());
    });
    setLookupFields(matchedFields);
  };

  const getInputTypeFragment = (type: INPUT_TYPE) => {
    switch (type) {
      case INPUT_TYPE.LOOKUP:
        return (
          <Box sx={{ ...classes.root }}>
            {field.values.length > 10 && (
            <mui.Grid item>
              <mui.TextField
                variant="outlined"
                placeholder="Search values"
                onChange={(e) => searchFields(e.target.value)}
                data-testid="search-lookup-values"
                className="lookup-search"
                InputProps={{
                  startAdornment: (
                    <mui.InputAdornment position="start">
                      <icons.Search />
                    </mui.InputAdornment>
                  ),
                }}
              />
            </mui.Grid>
            )}
            {lookupFields.map((val: LookupResponse) => (
              <Box key={`${filterName}:${val.code || val.id}`}>
                <mui.Checkbox
                  onClick={() => handleLookupChange(val)}
                  checked={isLookupChecked(val)}
                  data-testid={`filter-checkbox-${filterName}:${val.code || val.id}`}
                  sx={{
                    '&.MuiButtonBase-root': {
                      padding: '9px',
                    },
                  }}
                />
                {' '}
                {formatFieldNames(val.displayName)}
              </Box>
            ))}
          </Box>
        );
      case INPUT_TYPE.BOOLEAN:
        return (
          <Box>
            <mui.Checkbox
              onClick={handleBooleanChange}
              checked={value === true || value === 'true'}
              data-testid={`filter-checkbox-${filterName}`}
              sx={{
                '&.MuiButtonBase-root': {
                  padding: '9px',
                },
              }}
            />
            {' '}
            {field.displayName}
          </Box>
        );
      case INPUT_TYPE.DATETIME:
        return (
          <mui.TextField
            type="date"
            InputLabelProps={{
              shrink: true,
            }}
            onChange={handleDateChange}
            value={value}
            data-testid={`filter-datepicker-${filterName}`}
            variant="standard"
            sx={{
              '.MuiInput-root:after': {
                borderBottom: `2px solid ${colors.tealBlue}`,
              },
            }}
            fullWidth
          />
        );
      case INPUT_TYPE.TEXT:
        return getTextFieldType(field.name);
      default:
        return null;
    }
  };

  return getInputTypeFragment(inputType);
}
