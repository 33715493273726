import React from 'react';
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6';
import { Box } from 'tl-storybook';

import AppRouter from '../AppRouter';
import ErrorSnackbar from '../ErrorSnackbar';
import AlertSnackbar from '../AlertSnackbar';
import Header from '../Header';
import LeftNav from '../LeftNav';
import PreviewDialog from '../PreviewDialog';
import { useAppSelector } from '../../../../interfaces/hooks';
import LoadingSpinner from '../LoadingSpinner';
import DialogMediator8Error from '../../Modal/DialogMediator8Error';

import { classes } from './AppWrapper.styles';

export default function AppWrapper() {
  const { previewOpen } = useAppSelector((state) => state.preview);
  const loadingSpinner = useAppSelector((state) => state.userFeedBack.loadingSpinner);
  const hideLeftNavPaths = ['/login', '/create-account'];
  const hideLeftNav = hideLeftNavPaths.includes(window.location.pathname);

  return (
    <QueryParamProvider adapter={ReactRouter6Adapter}>
      <Box data-testid="app-wrapper">
        <ErrorSnackbar />
        <AlertSnackbar />
        <DialogMediator8Error />
        {previewOpen && <PreviewDialog />}
        <Header />
        <Box>
          {!hideLeftNav && <LeftNav />}
          <Box
            sx={{
              ...classes.contentWrapper,
              ...(hideLeftNav && classes.loginPage),
            }}
          >
            {loadingSpinner && (
              <LoadingSpinner size="100px" />
            )}
            <AppRouter />
          </Box>
        </Box>
      </Box>
    </QueryParamProvider>
  );
}
