import React, { useEffect, useState } from 'react';
import { NbcuSidenav, Box, icons } from 'tl-storybook';
import { useLocation } from 'react-router-dom';

import { AppRouteDefinition } from '../../../../interfaces/route';
import { classes } from './LeftNav.styles';

const navBtns: AppRouteDefinition[] = [{
  url: '/work-orders',
  icon: icons.Inbox,
  disabled: false,
  label: 'Work Orders',
},
{
  url: '/assets',
  icon: icons.PermMedia,
  label: 'Assets',
},
{
  url: '/collections',
  icon: icons.FilterNone,
  label: 'Collections',
},
{
  url: '/clientportal',
  icon: icons.InfoOutlined,
  disabled: true,
  label: 'Client Portal',
},
{
  url: '/admin',
  icon: icons.Security,
  disabled: true,
  label: 'Admin',
},
{
  url: '/login',
  icon: icons.ExitToApp,
  label: 'Logout',
  alignBottom: true,
  onClick: () => {
    // TODO: implement logout functionality here
  },
}];

export default function LeftNav() {
  const location = useLocation();

  const [items, setItems] = useState(navBtns);

  useEffect(() => {
    if (location.pathname === '/assets') {
      items[1].url = `/assets${location.search || ''}`;
      setItems([...items]);
    }
  }, [location.search]);

  return (
    <Box sx={{ ...classes.root }}>
      <NbcuSidenav items={items} />
    </Box>
  );
}
