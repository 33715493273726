import React, { useEffect, useState } from 'react';
import { mui } from 'tl-storybook';
import { useLocation } from 'react-router-dom';
import { FilterDrawerValue } from '../../../../interfaces/drawer-item';
import { LookupResponse, SearchableField } from '../../../../interfaces/searchable-field';
import { formatOptions } from '../../../../work-orders/consts/dropdown-options';
import { formatSupplyOptions } from '../../../../studio-supply-orders/const/dropdown-options';
import { colors } from '../../../../../styles/base/colors';
import { useAppSelector } from '../../../../interfaces/hooks';
import { useBrandsFilter } from '../../../../hooks/useBrandsFilter';

import { classes } from './BrandFilter.styles';

interface Props {
changeFilter: (field: SearchableField, value: FilterDrawerValue) => void;
}

export default function BrandFilter(props: Props) {
  const { changeFilter } = props;
  const assetsFilters = useAppSelector((state) => state.filter.assetsFilters);
  const workOrdersFilters = useAppSelector((state) => state.filter.workOrdersFilters);
  const studioSupplyOrdersFilters = useAppSelector((state) => state.filter.studioSupplyOrdersFilters);
  const networks = useAppSelector((state) => state.options.networks);
  const { user } = useAppSelector((state) => state.appUser);

  const [selectedBrands, updateSelectedBrands] = useState<string[]>([]);
  const [brandValues, updateBrandValues] = useState<LookupResponse[]>([]);
  const location = useLocation();

  useEffect(() => {
    let selectedFilters = [];
    switch (location.pathname) {
      case '/work-orders':
        selectedFilters = workOrdersFilters;
        break;
      case '/studio-supply-orders':
        selectedFilters = studioSupplyOrdersFilters;
        break;
      default:
        selectedFilters = assetsFilters;
    }
    const brandItems = (selectedFilters.find((item: SearchableField) => item.displayName === 'Brand')
      ?.value as LookupResponse[])?.map((val) => val?.displayName);
    updateSelectedBrands(brandItems || []);
  }, [assetsFilters, workOrdersFilters, studioSupplyOrdersFilters]);

  useEffect(() => {
    if (window.location.href.includes('work-orders')) {
      updateBrandValues(formatOptions(useBrandsFilter({ allBrands: networks, user })));
    } else {
      updateBrandValues(formatSupplyOptions(useBrandsFilter({ allBrands: networks, user })));
    }
  }, [networks]);

  const handleBrandChange = (event: any) => {
    const selected = event.target.value;
    const brandObjects: any = [];
    const addBrands = (brand: any) => {
      if (selected.includes(brand.displayName)) {
        brandObjects.push(brand);
      }
    };

    if (location.pathname.includes('assets')) {
      formatSupplyOptions(useBrandsFilter({ allBrands: networks, user })).forEach((brand: any) => {
        addBrands(brand);
      });

      const networkValues = formatSupplyOptions(useBrandsFilter({ allBrands: networks, user }));
      changeFilter({
        comparators: ['IS', 'IS_NOT'],
        displayName: 'Brand',
        group: 'USER',
        name: 'brand',
        sortable: true,
        type: 'LOOKUP',
        values: networkValues,
      }, brandObjects);
      return;
    }

    if (location.pathname.includes('work-orders')) {
      formatOptions(useBrandsFilter({ allBrands: networks, user })).forEach((brand: any) => {
        addBrands(brand);
      });
      const networkValues = formatOptions(useBrandsFilter({ allBrands: networks, user }));
      changeFilter({
        comparators: ['IS', 'IS_NOT'],
        displayName: 'Brand',
        group: 'USER',
        name: 'asset_userNetwork_id',
        sortable: true,
        type: 'LOOKUP',
        values: networkValues,
      }, brandObjects);
    }

    if (location.pathname.includes('studio-supply-orders')) {
      formatSupplyOptions(useBrandsFilter({ allBrands: networks, user })).forEach((brand: any) => {
        addBrands(brand);
      });

      const networkValues = formatSupplyOptions(useBrandsFilter({ allBrands: networks, user }));
      changeFilter({
        comparators: ['IS', 'IS_NOT'],
        displayName: 'Brand',
        group: 'USER',
        name: 'brand',
        sortable: true,
        type: 'LOOKUP',
        values: networkValues,
      }, brandObjects);
    }
  };

  return brandValues && (
    <mui.FormControl
      className="brand-form-control"
      sx={{
        ...classes.root,
        '.MuiOutlinedInput-input': {
          padding: '12.5px 32px 12.5px 14px',
        },
        ...(selectedBrands.length === 0 && { '.MuiFormLabel-root': { transform: 'translate(14px, 12.5px) scale(1)' } }),
      }}
    >
      <mui.InputLabel
        id="brand-label"
      >
        Brand
      </mui.InputLabel>
      <mui.Select
        multiple
        className="brand-select"
        labelId="brand-label"
        label="Brand"
        variant="outlined"
        value={selectedBrands}
        onChange={handleBrandChange}
        renderValue={(selected) => (selected as string[]).join(', ')}
        data-automation-id="brand-filter-select"
        MenuProps={{
          sx: {
            '.MuiMenu-list': {
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
            },
          },
        }}
      >
        {brandValues.map((brand: LookupResponse) => (
          <mui.MenuItem
            value={brand.displayName}
            key={brand.code}
            sx={{
              '&.MuiMenuItem-root': {
                display: 'flex',
                justifyContent: 'left',
                width: '100%',
                padding: '6px 16px',
                '&.Mui-selected': {
                  backgroundColor: 'rgba(0, 0, 0, 0.08)',
                },
              },
            }}
          >
            <mui.Checkbox
              checked={selectedBrands.findIndex((item) => item === brand.displayName) !== -1}
              data-testid={`checkbox-${brand.displayName}`}
              sx={{
                '&.MuiButtonBase-root': {
                  padding: '9px',
                },
                '&.Mui-checked': {
                  color: colors.tealGreen,
                },
              }}
            />
            {' '}
            {brand.displayName}
          </mui.MenuItem>
        ))}
      </mui.Select>
    </mui.FormControl>
  );
}
