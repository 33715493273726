import axios from 'axios';
import { createSlice } from '@reduxjs/toolkit';
import { FilterDrawerItem } from 'src/app/interfaces/filter-drawer-item';
import { LookupResponse, SearchableField } from '../../interfaces/searchable-field';
import { SavedFilter } from '../../interfaces/saved-filter';
import { CONTEXT } from '../consts/CONTEXT';
import { AppGetState, AppDispatch } from '../../store';
import { API_ENDPOINTS } from '../consts/API_ENDPOINTS';

const FilterSlice = createSlice({
  name: 'filter',
  initialState: {
    assetsFilters: [],
    workOrdersFilters: [],
    studioSupplyOrdersFilters: [],
    savedFilters: [],
    confirmRemoveFilter: null,
    urlQueryParamsString: undefined,
    filterToEdit: undefined,
    filterObjects: [],
  },
  reducers: {
    setSavedFilters(state, action) {
      state.savedFilters = action.payload;
    },
    setConfirmRemoveFilter(state, action) {
      state.confirmRemoveFilter = action.payload;
    },
    updateUrlQueryParamsString(state, action) {
      state.urlQueryParamsString = action.payload;
    },
    setFilterToEdit(state, action) {
      state.filterToEdit = action.payload;
    },
    updateSelectedFilters(state, action) {
      const { pathname } = window.location;

      switch (pathname) {
        case '/work-orders':
          state.workOrdersFilters = action.payload;
          break;
        case '/studio-supply-orders':
          state.studioSupplyOrdersFilters = action.payload;
          break;
        default:
          state.assetsFilters = action.payload;
      }
    },
    setFilterObject(state, action) {
      const { value, name } = action.payload;
      const currentIndex = state.filterObjects.findIndex((filterObject) => filterObject.name === name);
      if (currentIndex === -1) {
        state.filterObjects.push(action.payload);
        return;
      }
      state.filterObjects[currentIndex].value = value;
    },
    updateFilterObject(state, action) {
      state.filterObjects = action.payload;
    },
    translateQueryParamsToFilters(state, action) {
      const { queryParams, sortedFilterData } = action.payload;
      const newSelectedFilters: SearchableField[] = [];
      const { pathname } = window.location;

      Object.keys(queryParams).forEach((paramKey: string) => {
        let isAfter = false;
        let isBefore = false;
        let isCode = false;
        if (queryParams[paramKey]) {
          // sorted without PMAM
          // const noPmam = paramKey.replace('pmam.', '').replace('asset_', '').replace('gtm_', '');
          // remove keywordContains for filter searches
          const noKeywordContains = paramKey
            .replace('-keywordContains', '')
            .replace('-contains', '')
            .replace('-after', '')
            .replace('-before', '')
            .replace('-lt-iso', '')
            .replace('-gt-iso', '')
            .replace('-code', '')
            .replace('-exact', '');

          if (paramKey.includes('-after') || paramKey.includes('-gt-iso')) {
            isAfter = true;
          }

          if (paramKey.includes('-before') || paramKey.includes('-lt-iso')) {
            isBefore = true;
          }

          if (paramKey.includes('-code')) {
            isCode = true;
          }

          const allFilters: any = [];
          const keys = Object.keys(sortedFilterData.az as FilterDrawerItem);
          keys.forEach((key) => {
            (sortedFilterData.az as FilterDrawerItem)[key].forEach((sortedFilter) => {
              allFilters.push(sortedFilter);
            });
          });

          let thisFilter = allFilters?.find(
            (item: any) => item.name === noKeywordContains,
          );

          if (!thisFilter && (paramKey === 'q' || paramKey === 'searchTerm')) {
            thisFilter = {
              displayName: 'Full Text Search',
              name: paramKey === 'q' ? 'q' : 'searchTerm',
              type: 'KEYWORD',
              sortable: false,
            };
          }

          // Add the new values
          if (thisFilter) {
            const newFilter = { ...thisFilter };
            if (Array.isArray(queryParams[paramKey])) {
              const newValues: LookupResponse[] = [];
              (queryParams[paramKey] as string[]).forEach((val: string) => {
                newValues.push(
                  newFilter.values.find((filterVal: any) => filterVal.id?.toString() === val || filterVal.code === val),
                );
              });

              newFilter.value = newValues;

              if (isCode) {
                newFilter.name = paramKey;
              }
            } else {
              (newFilter.value as string) = queryParams[paramKey] as string;
              if (isAfter || isBefore) {
                const isWorkOrders = window.location.href.includes('work-orders');
                newFilter.name = paramKey;
                if (isAfter) {
                  newFilter.displayName = thisFilter.displayName + (isWorkOrders ? '-gt-iso' : '-after');
                }
                if (isBefore) {
                  newFilter.displayName = thisFilter.displayName + (isWorkOrders ? '-lt-iso' : '-before');
                }
              }
            }
            newSelectedFilters.push(newFilter);
          }
        }
      });

      switch (pathname) {
        case '/work-orders':
          state.workOrdersFilters = newSelectedFilters;
          sessionStorage.workOrdersFilters = JSON.stringify(newSelectedFilters);
          sessionStorage.workOrdersQuery = JSON.stringify(queryParams);
          sessionStorage.workOrdersSearchParams = window.location.search;
          break;
        case '/studio-supply-orders':
          state.studioSupplyOrdersFilters = newSelectedFilters;
          sessionStorage.studioSupplyOrdersFilters = JSON.stringify(newSelectedFilters);
          sessionStorage.studioSupplyOrdersQuery = JSON.stringify(queryParams);
          sessionStorage.studioSupplyOrdersSearchParams = window.location.search;
          break;
        default:
          state.assetsFilters = newSelectedFilters;
          sessionStorage.assetsFilters = JSON.stringify(newSelectedFilters);
          sessionStorage.assetsQuery = JSON.stringify(queryParams);
          sessionStorage.assetsSearchParams = window.location.search || '';
      }
    },
  },
});

const { actions, reducer } = FilterSlice;
export const {
  translateQueryParamsToFilters,
  setSavedFilters,
  setConfirmRemoveFilter,
  updateUrlQueryParamsString,
  setFilterToEdit,
  updateSelectedFilters,
  setFilterObject,
  updateFilterObject,
} = actions;

export const doUpdateSavedFilters = (
  filters: SavedFilter[],
  context: CONTEXT,
) => async (dispatch: AppDispatch, getState: AppGetState) => {
  const state = getState();
  const { savedFilters } = state.filter;
  const originalFilters = [...savedFilters];
  dispatch(setSavedFilters(filters));

  const url = `${API_ENDPOINTS.SAVED_SEARCHES}?context=${context}`;
  await axios
    .post<SavedFilter[]>(url, filters)
    .then((resp) => {
      dispatch(setSavedFilters(resp.data));
    })
    .catch(() => {
      dispatch(setSavedFilters(originalFilters));
    });
};

// Select and toggle button groups send the full state as their value, not just what was toggled
// This function figures out what's actually toggled to plug it into our system
// NOTE: This function was in the old FilterContext but was not being call... Is this dead code? Should it be removed?
// Make this a Thunk function to gain access to state and return valTlToggle from the returned function to imitate
// this functions behavior in the React Toolkit structure.
// const makeToggleItemFromArray = (displayName: string, selection: string[], possibleValues: LookupResponse[]) => {
//   const thisFilter = selectedFilters.find((item: SearchableField) => item.displayName === displayName);
//   let valToToggle;
//   if (!selection.length) {
//     // If the array is empty, it's obviously whatever is left in the current filter
//     valToToggle = (thisFilter.value as LookupResponse[])[0];
//   } else {
//     if (!thisFilter) {
//       // If the filter doesn't exist, we need to create it
//       valToToggle = possibleValues.filter((poss) => poss.displayName === selection[0])[0];
//     } else {
//       // If it does exist, we need to determine which state- new (selection) or old (existing filter) is longer
//       // Then we can determine the value that isn't common between them and toggle it
//       const selectionIsLonger = selection.length > (thisFilter.value as LookupResponse[]).length;
//       if (selectionIsLonger) {
//         const thisFilterValuesString = (thisFilter.value as LookupResponse[]).map((val) => val.displayName);
//         const onlyNewSelectionItem = selection.filter((item) => !thisFilterValuesString.includes(item))[0];
//         const thisValue = possibleValues.filter((item) => onlyNewSelectionItem.includes(item.displayName))[0];
//         valToToggle = thisValue;
//       } else {
//         valToToggle = (thisFilter.value as LookupResponse[]).filter(
//           (item) => !selection.includes(item.displayName)
//         )[0];
//       }
//     }
//   }
//   return valToToggle;
// };

export default reducer;
